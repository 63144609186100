import { ChangeEvent, MouseEvent, useState } from "react";
import { Checkbox, Intent } from "@blueprintjs/core";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { unitIsValid } from "@rollup-io/engineering";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { InputGroup } from "@components/InputGroup";
import { NumericInput } from "@components/NumericInput";
import { Popover } from "@components/Popover";
import ValidationFormulaMenu from "@components/Requirements/RequirementsTable/Cells/ValidationFormulaMenu";
import { ManualVerificationStatus, SuccessCriteriaType } from "@rollup-api/models";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

import styles from "./VerificationLogic.module.scss";

interface IVerificationLogicProps {
  requirementBlock: IRequirementBlock;
  outlined?: boolean;
  className?: string;
  automaticClassName?: string;
  valueInputClassName?: string;
  unitInputClassName?: string;
}

const VerificationLogic = (props: IVerificationLogicProps) => {
  const { requirementBlock, outlined, className, automaticClassName } = props;
  const { valueInputClassName, unitInputClassName } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleMenuClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsPopoverOpen(false);
  };

  const handleValidationChange = (_: number, value: string) => {
    requirementBlock.updateValidation(value);
  };

  const handleUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== requirementBlock.unit) {
      requirementBlock.setUnit(value);
    }
  };

  const debouncedHandleUnitChange = useDebouncedCallback(handleUnitChange, 500);

  const renderLabel = () => {
    const { manualVerification } = requirementBlock;
    if (manualVerification === ManualVerificationStatus.Verified) {
      return "Verified";
    } else if (manualVerification === ManualVerificationStatus.NotVerified) {
      return "Not Verified";
    }
    return "Not set";
  };

  const renderManualValidation = () => {
    return (
      <Checkbox
        className={styles.verificationLogicCheckbox}
        label={renderLabel()}
        checked={requirementBlock.manualVerification == ManualVerificationStatus.Verified}
        indeterminate={requirementBlock.manualVerification == ManualVerificationStatus.Undefined}
        onChange={requirementBlock.setManualVerification}
      />
    );
  };

  const renderAutomaticValidation = () => {
    return (
      <div className={classNames(styles.verificationLogicAutomatic, automaticClassName)}>
        <Popover
          isOpen={isPopoverOpen}
          onInteraction={setIsPopoverOpen}
          content={<ValidationFormulaMenu onClick={handleMenuClick} requirementBlock={requirementBlock} />}
          placement="bottom"
        >
          <Button
            className={styles.verificationLogicOperatorButton}
            icon={requirementBlock.validationOperation}
            disabled={requirementBlock.locked}
            e2eIdentifiers={["verification-status-cell", "down-arrow"]}
            outlined={outlined}
            minimal
          />
        </Popover>
        <NumericInput
          className={classNames(styles.verificationLogicValueInput, valueInputClassName, {
            [styles.highlightBackgroundOnFocus]: !outlined,
          })}
          disabled={requirementBlock.locked}
          maxLength={36}
          fill
          placeholder="Value"
          defaultValue={requirementBlock.validationFormula}
          value={requirementBlock.validationFormula}
          buttonPosition="none"
          allowNumericCharactersOnly
          onValueChange={handleValidationChange}
        />
        <InputGroup
          className={classNames(styles.verificationLogicUnitInput, unitInputClassName, {
            [styles.highlightBackgroundOnFocus]: !outlined,
          })}
          disabled={requirementBlock.locked}
          maxLength={20}
          placeholder="Unit"
          defaultValue={requirementBlock.unit}
          intent={unitIsValid(requirementBlock.unit) ? Intent.NONE : Intent.WARNING}
          onChange={debouncedHandleUnitChange}
          disableOutline={!outlined}
          e2eIdentifiers="unit"
        />
      </div>
    );
  };

  return (
    <div className={className}>
      {requirementBlock.successCriteria === SuccessCriteriaType.Automatic ? renderAutomaticValidation() : renderManualValidation()}
    </div>
  );
};

export default observer(VerificationLogic);
