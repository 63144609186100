import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import CommentsPanel from "@components/CommentsPanel/CommentsPanel";
import { RightSidePanels } from "@components/RightSidePanels";
import appStore from "@store/AppStore";
import { ICommentFeed } from "@store/CommentFeedStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { ReqSidePanel } from "@store/SidePanelStore";

import { RequirementDetailsPanel } from "../RequirementDetailsPanel";

interface IRequirementsRightSidePanelsProps {
  commentFeed: ICommentFeed;
  childrenIds: string[];
  activeReqBlock?: IRequirementBlock;
  activeSidePanel?: ReqSidePanel;
  onClearCommentHistory(): void;
}

const RequirementsRightSidePanels = (props: IRequirementsRightSidePanelsProps) => {
  const { commentFeed, activeSidePanel, childrenIds, activeReqBlock, onClearCommentHistory } = props;
  const { pinnedReqSidePanels } = appStore.env.sidePanel;

  const renderPanel = (panel?: ReqSidePanel) => {
    switch (panel) {
      case ReqSidePanel.Comments:
        return (
          <CommentsPanel
            commentFeed={commentFeed}
            isFetchingComments={commentFeed.isFetchingCommentFeed}
            additionalParentIds={childrenIds}
            onClearCommentHistory={onClearCommentHistory}
          />
        );
      case ReqSidePanel.RequirementDetails:
        if (!activeReqBlock) {
          return <NonIdealState title="No active Requirement Block" description="You can select a block by clicking on its field" />;
        }

        return <RequirementDetailsPanel reqBlock={activeReqBlock} />;

      default:
        return null;
    }
  };
  return (
    <RightSidePanels<ReqSidePanel> activeSidePanel={activeSidePanel} pinnedSidePanels={pinnedReqSidePanels} renderPanel={renderPanel} />
  );
};

export default observer(RequirementsRightSidePanels);
