import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

export enum ERequirementsTableColumn {
  ACTIONS = "Actions",
  ID = "ID",
  LEVEL = "Level",
  TITLE = "Title",
  FUNCTIONAL_TYPE = "Type",
  COMMENTS = "Comments",
  DESCRIPTION = "Description",
  RATIONALE = "Rationale",
  LINKED_BLOCK = "Linked Block",
  LINKED_PROPERTY = "Linked Property",
  METHOD = "Verif. Method",
  VERIFICATION = "Verification",
  SUCCESS_CRITERIA = "Success Criteria",
  VERIFICATION_STATUS = "Verif. Status",
  NOTES = "Notes",
}

export type RequirementsCsvColumn = Extract<
  keyof IRequirementBlock,
  | "visibleId"
  | "level"
  | "label"
  | "functionalType"
  | "description"
  | "rationale"
  | "linkedProperty"
  | "verificationMethods"
  | "validationOperation"
  | "validationFormula"
  | "unit"
  | "successCriteria"
  | "comment"
  | "note"
>;

export type RequirementsCsvColumnMap = Record<RequirementsCsvColumn, string>;

export const requirementsCsvColumnMap: RequirementsCsvColumnMap = {
  visibleId: "",
  level: "",
  label: "",
  functionalType: "",
  description: "",
  rationale: "",
  linkedProperty: "",
  verificationMethods: "",
  validationOperation: "",
  validationFormula: "",
  unit: "",
  successCriteria: "",
  note: "",
};

export const requirementsCsvColumnNameMap: RequirementsCsvColumnMap = {
  visibleId: "ID",
  level: "Level",
  label: "Title",
  functionalType: "Type",
  description: "Description",
  rationale: "Rationale",
  linkedProperty: "Linked Property",
  verificationMethods: "Verif. Methods",
  validationOperation: "Verif. Operation",
  validationFormula: "Verif. Value",
  unit: "Verif. Unit",
  successCriteria: "Success Criteria",
  note: "Notes",
};

export const HEADER_ROW_HEIGHT = 32;
export const RATIONALE_PLACEHOLDER = "Write rationale";
export const CELL_EDIT_FOCUS = "ag-edit-focus";

export const defaultColDef = {
  editable: false,
  autoHeight: true,
  suppressMovable: false,
  singleClickEdit: true,
  lockPinned: true,
  resizable: true,
};
