import { observer } from "mobx-react";

import { BlockIcon, CustomIcon } from "@components/CustomIcon";
import { BlockType } from "@store/BlockStore";

type Props = {
  types: BlockType[];
};

const BlockTypeIcon = (props: Props) => {
  const { types } = props;

  if (types.includes(BlockType.Product)) {
    return <CustomIcon icon={BlockIcon.Product} />;
  } else if (types.includes(BlockType.Subsystem)) {
    return <CustomIcon icon={BlockIcon.Subsystem} />;
  } else if (types.includes(BlockType.Assembly)) {
    return <CustomIcon icon={BlockIcon.Assembly} />;
  } else if (types.includes(BlockType.Part)) {
    return <CustomIcon icon={BlockIcon.Part} />;
  }
  return null;
};

export default observer(BlockTypeIcon);
