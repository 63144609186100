import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { SearchInput } from "@components/SearchInput";
import SearchResults from "@components/SearchResults";
import { EPageName, useAppNavigate } from "@router/hooks/useAppNavigate";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";

import RequirementsSidebarItem from "./RequirementsSidebarItem";

import "./RequirementsSidebar.scss";

interface IRequirementsSidebarProps {
  minimal?: boolean;
}

function RequirementsSidebar(props: IRequirementsSidebarProps) {
  const { minimal } = props;
  const { requirementId } = useParams();
  const location = useLocation();
  const workspace = useWorkspace();
  const { navigateToRequirement } = useAppNavigate();
  const [searchValue, setSearchValue] = useState("");
  const reqPages = workspace.requirementsModule.values;
  const searchResult = reqPages.filter(reqPage => reqPage.label.toLowerCase().includes(searchValue.toLowerCase()));

  const renderSearch = () => {
    if (minimal) {
      return <BlueprintIcon className="reports-sidebar--search-icon" icon="search" outline />;
    }

    return <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />;
  };

  return (
    <div className="requirements-sidebar">
      <NavLinkAsMenuItem
        to={`/workspaces/${workspace.id}/${EPageName.Requirements}/${EPageName.Configuration}`}
        text="Configuration"
        icon="cog"
        selected={location.pathname.includes(EPageName.Configuration)}
        e2eIdentifiers="requirements-settings"
      />
      {renderSearch()}
      {searchValue ? (
        <SearchResults activeId={requirementId} onClick={navigateToRequirement} items={searchResult} />
      ) : (
        reqPages.toReversed().map(reqPage => <RequirementsSidebarItem key={reqPage.id} reqPage={reqPage} minimal={minimal} />)
      )}
    </div>
  );
}

export default observer(RequirementsSidebar);
