import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, BackgroundVariant, Controls, Edge, MarkerType, Node, Position, useReactFlow, XYPosition } from "reactflow";
import { Colors } from "@blueprintjs/colors";
import { ButtonGroup, NonIdealState } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { DependencyConnectionType, DependencyGraph, separateGraph } from "@rollup-io/engineering";
import { ElkExtendedEdge, ElkNode } from "elkjs/lib/elk-api";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import CodeBlockNode, { CodeBlockNodeData } from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/DependencyFlow/CodeBlockNode";
import DataLinkNode, { DataLinkNodeData } from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/DependencyFlow/DataLinkNode";
import DataSinkEntryNode, {
  DataSinkEntryNodeData,
} from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/DependencyFlow/DataSinkEntryNode";
import PropertyNode, { PropertyNodeData } from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/DependencyFlow/PropertyNode";
import UnknownNode, { UnknownNodeData } from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/DependencyFlow/UnknownNode";
import { CustomNodeType } from "@components/Modeling/ModelingFrame/FlowView/CustomNodes/types";
import { IAnalysisInput } from "@store/Analysis/AnalysisInputStore";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";
import { IAnalysis } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { getAnalysisInputById, getAnalysisOutputById, getPropertyInstanceById } from "@utilities";

import { elk } from "../elk";

import "../FlowView.scss";

export type FlowViewPropertiesProps = {
  propertyInstance?: IPropertyInstance;
  showRollups?: boolean;
  showFullSubgraph?: boolean;
};

export enum FlowNodeTypes {
  Property = "property",
  DataLink = "data-link",
  CodeBlock = "code-block",
  DataSinkEntry = "data-sink-entry",
  Unknown = "unknown",
}

export type DependencyNodeData = PropertyNodeData | DataLinkNodeData | CodeBlockNodeData | UnknownNodeData;

const NODE_WIDTH = 140;
const NODE_WIDTH_PADDING = 48;
const NODE_ICON_WIDTH = 32;
const HANDLE_WIDTH = 20;
const NODE_HEIGHT = 74;
const AUTO_ZOOM_DURATION = 500;
const ZOOM_DURATION = 100;
const MAX_ZOOM = 1.0;
const MIN_ZOOM = 0.1;

function FlowViewProperties({ propertyInstance, showFullSubgraph, showRollups }: FlowViewPropertiesProps) {
  const [arrangedGraph, setArrangedGraph] = useState<{ nodes: Node[]; edges: Edge[] }>({ edges: [], nodes: [] });
  const { fitView, setCenter, zoomIn, zoomOut } = useReactFlow();
  const workspace = useWorkspace();

  let inputGraph: DependencyGraph | undefined = undefined;
  const selectedProperty = propertyInstance ?? appStore.env.activePropertyInstance;
  if (selectedProperty) {
    inputGraph = showFullSubgraph ? selectedProperty.dependencySubGraph : selectedProperty.dependencyGraph;
  } else if (appStore.env.activeBlock) {
    inputGraph = appStore.env.activeBlock.dependencySubGraph;
  }

  // Rollup connections must be shown if the full subgraph is shown, otherwise we have dangling blocks
  showRollups ||= showFullSubgraph;

  const handleNodeClicked = (node: Node<PropertyNodeData>) => {
    const { id } = node.data;
    const clickedProperty = getPropertyInstanceById(id);
    if (clickedProperty) {
      appStore.env.setActivePropertyInstance(clickedProperty);
    }
  };

  const handleNodeEditStarted = useCallback(
    (_id: string, position: XYPosition) => {
      setCenter(position.x + NODE_WIDTH / 2, position.y + NODE_HEIGHT / 2, { zoom: 1, duration: AUTO_ZOOM_DURATION });
    },
    [setCenter]
  );

  // Helpers functions.
  const handleGraphUpdate = useCallback(
    (generatedGraph: ElkNode) => {
      if (!inputGraph || !generatedGraph?.children) {
        return;
      }

      const nodes: Node<DependencyNodeData>[] = [];

      for (const elkNode of generatedGraph.children) {
        const data = inputGraph.getNodeAttributes(elkNode.id);
        if (!data) {
          continue;
        }

        const property = data.property as IPropertyInstance;
        const dataLink = data.dataLink;
        const analysisOutput = data.analysisOutput as IAnalysisOutput;
        const codeBlock = data.codeBlock as IAnalysis;
        const analysisInput = data.analysisInput as IAnalysisInput;
        const dataSinkEntry = data.dataSinkEntry as IDataSinkEntry;

        const defaultNodeProps = {
          id: elkNode.id,
          width: elkNode.width,
          height: elkNode.height,
          targetPosition: Position.Left,
          sourcePosition: Position.Right,
          connectable: false,
          position: { x: elkNode.x ?? 0, y: elkNode.y ?? 0 },
          draggable: false,
        };

        // Skip code inputs and outputs, they are connected as handles in the code block itself
        if (analysisInput || analysisOutput) {
          continue;
        }

        if (property) {
          if (!property.propertyDefinitionId) {
            continue;
          }

          // Custom data for the node renderer.
          const data: PropertyNodeData = {
            type: CustomNodeType.DEFAULT,
            id: property.id,
            propertyInstance: property,
            active: selectedProperty?.dependencyGraph?.hasNode(elkNode.id) ?? false,
            onEditClicked: handleNodeEditStarted,
          };

          nodes.push({ ...defaultNodeProps, type: FlowNodeTypes.Property, data });
        } else if (dataLink) {
          if (!dataLink.dataSourceId) {
            continue;
          }

          const data: DataLinkNodeData = {
            type: CustomNodeType.DEFAULT,
            id: dataLink.id,
            dataLink,
            active: selectedProperty?.dependencyGraph?.hasNode(elkNode.id) ?? false,
          };

          nodes.push({ ...defaultNodeProps, type: FlowNodeTypes.DataLink, data });
        } else if (dataSinkEntry) {
          if (!dataSinkEntry.dataSink) {
            console.warn("Data sink entry without data sink", dataSinkEntry);
            continue;
          }

          const data: DataSinkEntryNodeData = {
            type: CustomNodeType.DEFAULT,
            id: dataSinkEntry.id,
            dataSinkEntry,
            active: selectedProperty?.dependencyGraph?.hasNode(elkNode.id) ?? false,
          };

          nodes.push({ ...defaultNodeProps, type: FlowNodeTypes.DataSinkEntry, data });
        } else if (codeBlock) {
          const data: CodeBlockNodeData = {
            type: CustomNodeType.DEFAULT,
            id: codeBlock.id,
            analysis: codeBlock,
            active: selectedProperty?.dependencyGraph?.hasNode(elkNode.id) ?? false,
            activeHandles: new Map<string, void>(),
          };

          nodes.push({ ...defaultNodeProps, type: FlowNodeTypes.CodeBlock, data });
        } else {
          const data: UnknownNodeData = {
            type: CustomNodeType.DEFAULT,
            id: elkNode.id,
            active: selectedProperty?.dependencyGraph?.hasNode(elkNode.id) ?? false,
          };

          nodes.push({ ...defaultNodeProps, type: FlowNodeTypes.Unknown, data });
        }
      }

      const edges: Edge[] = [];
      const generatedEdges = generatedGraph.edges as (ElkExtendedEdge & { type: DependencyConnectionType })[];

      if (generatedEdges?.length) {
        for (const elkEdge of generatedEdges) {
          let source = elkEdge.sources?.[0];
          let target = elkEdge.targets?.[0];
          const isRollup = elkEdge.type === DependencyConnectionType.Rollup;
          const isAnalysisOutputEdge = elkEdge.type === DependencyConnectionType.AnalysisOutput;
          const isAnalysisInputEdge = elkEdge.type === DependencyConnectionType.PropertyReference;
          let sourceHandle: string | undefined = undefined;
          let targetHandle: string | undefined = undefined;

          // Code input and output edges are connected as handles in the code block, rather than separate nodes
          if (isAnalysisOutputEdge) {
            const analysisOutput = getAnalysisOutputById(source);
            if (analysisOutput) {
              // Collapse codeBlock => analysisOutput => property into codeBlock => property
              source = analysisOutput.analysisId;
              sourceHandle = analysisOutput.id;
              // Add the source handle to the code block in order to highlight it
              const nodeData = nodes.find(n => n.id === analysisOutput.analysisId)?.data as CodeBlockNodeData | undefined;
              nodeData?.activeHandles?.set(sourceHandle);
            }
          } else if (isAnalysisInputEdge) {
            const analysisInput = getAnalysisInputById(target);
            if (analysisInput) {
              // Collapse property => analysisInput => codeBlock into property => codeBlock
              target = analysisInput.analysisId;
              targetHandle = analysisInput.id;
              // Add the target handle to the code block in order to highlight it
              const nodeData = nodes.find(n => n.id === analysisInput.analysisId)?.data as CodeBlockNodeData | undefined;
              nodeData?.activeHandles?.set(targetHandle);
            }
          }

          if (source && target) {
            const isActive = selectedProperty?.dependencyGraph?.hasNode(target);
            const style = {
              stroke: isRollup ? Colors.GRAY1 : Colors.BLUE4,
              opacity: isActive ? 1 : 0.3,
            };

            edges.push({
              id: `${source}-${target}`,
              source,
              sourceHandle,
              target,
              targetHandle,
              animated: isActive,
              type: "default",
              interactionWidth: 0,
              style,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 25,
                height: 25,
                strokeWidth: 0,
                color: style.stroke,
              },
            });
          }
        }
      }

      setArrangedGraph({ nodes, edges });
    },
    [inputGraph, selectedProperty?.dependencyGraph, handleNodeEditStarted]
  );

  // Effects.
  useEffect(() => {
    if (!inputGraph) {
      return;
    }

    let workingGraph = inputGraph;

    if (!showRollups && selectedProperty) {
      const edgesToDrop = workingGraph.filterEdges((_, edge) => edge.type === DependencyConnectionType.Rollup);

      // Remove rollups and split subgraphs to isolate the selected property.
      if (edgesToDrop.length) {
        workingGraph = workingGraph.copy();
        for (const edge of edgesToDrop) {
          workingGraph.dropEdge(edge);
        }
        const subGraphs = separateGraph(workingGraph) as DependencyGraph[];
        for (const subGraph of subGraphs) {
          if (subGraph.hasNode(selectedProperty.id)) {
            workingGraph = subGraph;
            break;
          }
        }
      }
    }

    const filteredNodes: ElkNode[] = [];
    const measureContext = document.createElement("canvas").getContext("2d");
    if (measureContext) {
      measureContext.font = "16px sans-serif";
    }

    workingGraph.forEachNode((id, node) => {
      const degree = workingGraph?.degree(id);
      // Only add nodes that have a connection to the selected property or are the selected property.
      if (degree || id === selectedProperty?.id) {
        const property = node.property as IPropertyInstance;
        const dataLink = node.dataLink;
        const analysisOutput = node.analysisOutput as IAnalysisOutput;
        const codeBlock = node.codeBlock as IAnalysis;
        const analysisInput = node.analysisInput as IAnalysisInput;
        const dataSinkEntry = node.dataSinkEntry as IDataSinkEntry;

        if (!property && !dataLink && !analysisOutput && !codeBlock && !analysisInput && !dataSinkEntry) {
          return;
        }

        // Measure the width of the node text in order to arrange the graph correctly
        let width = NODE_WIDTH;
        if (measureContext) {
          if (property) {
            const titleText = property.parentBlock.label;
            const contentText = `${property.label} = ${property.numericText}`;
            width = Math.max(measureContext.measureText(titleText).width, measureContext.measureText(contentText).width);
          } else if (dataLink) {
            const contentText = `${dataLink.query} = ${dataLink.value}`;
            const dataSource = workspace.dataConnection.dataSourceMap.get(dataLink.dataSourceId);
            const titleText = dataSource?.label ?? "";
            width = Math.max(measureContext.measureText(titleText).width, measureContext.measureText(contentText).width);
          } else if (dataSinkEntry) {
            const contentText = `${dataSinkEntry.key} = ${dataSinkEntry.value}`;
            const titleText = dataSinkEntry?.dataSink?.label ?? "";
            width = Math.max(measureContext.measureText(titleText).width, measureContext.measureText(contentText).width);
          } else if (codeBlock) {
            const titleText = codeBlock.label;
            let ioWidth = 0;
            for (const io of codeBlock.connections) {
              ioWidth = Math.max(measureContext.measureText(io.label).width + HANDLE_WIDTH, ioWidth);
            }
            //Code blocks also have an actions button
            width = Math.max(measureContext.measureText(titleText).width, ioWidth) + NODE_ICON_WIDTH;
          }
          const hasIcon = !!dataLink || !!codeBlock;
          width = Math.ceil(width) + NODE_WIDTH_PADDING + (hasIcon ? NODE_ICON_WIDTH : 0);
        }
        filteredNodes.push({ id, width, height: NODE_HEIGHT });
      }
    });

    const edges = workingGraph.mapEdges((id, edge, source, target) => {
      return {
        id,
        type: edge.type,
        sources: [source],
        targets: [target],
      };
    });

    const graph: ElkNode = {
      id: "root",
      children: filteredNodes,
      edges,
    };

    elk
      .layout(graph, { layoutOptions: { "elk.algorithm": "layered", "elk.direction": "left" } })
      .then(handleGraphUpdate)
      .catch(console.error);
  }, [
    inputGraph,
    showRollups,
    propertyInstance,
    selectedProperty,
    handleGraphUpdate,
    workspace.dataConnection.dataSourceMap,
    workspace.analysis.analysisOutputMap,
  ]);

  useEffect(() => {
    fitView();
  }, [arrangedGraph, fitView]);

  const nodeTypes = useMemo(
    () => ({
      [FlowNodeTypes.Property]: PropertyNode,
      [FlowNodeTypes.DataLink]: DataLinkNode,
      [FlowNodeTypes.CodeBlock]: CodeBlockNode,
      [FlowNodeTypes.DataSinkEntry]: DataSinkEntryNode,
      [FlowNodeTypes.Unknown]: UnknownNode,
    }),
    []
  );

  if (!inputGraph) {
    return (
      <NonIdealState
        icon="info-sign"
        title="No property or block selected"
        description="Select a block or property to view its dependencies"
      />
    );
  }

  return (
    <ReactFlow
      maxZoom={MAX_ZOOM}
      minZoom={MIN_ZOOM}
      fitView
      nodes={arrangedGraph.nodes}
      edges={arrangedGraph.edges}
      className="flow-view-properties"
      nodeTypes={nodeTypes}
      onNodeClick={(_event, node) => handleNodeClicked(node)}
      proOptions={{ hideAttribution: true }}
    >
      <Controls showZoom={false} showInteractive={false} showFitView={false}>
        <ButtonGroup vertical>
          <Button icon="zoom-in" e2eIdentifiers="zoom-in" onClick={() => zoomIn({ duration: ZOOM_DURATION })} />
          <Button icon="zoom-out" e2eIdentifiers="zoom-out" onClick={() => zoomOut({ duration: ZOOM_DURATION })} />
          <Button icon="zoom-to-fit" e2eIdentifiers="fit-view" onClick={() => fitView({ duration: ZOOM_DURATION })} />
        </ButtonGroup>
      </Controls>
      <Background variant={BackgroundVariant.Dots} gap={10} size={0.5} />
    </ReactFlow>
  );
}

/** Exports. */
export default observer(FlowViewProperties);
