import { memo } from "react";
import { CustomCellEditorProps } from "ag-grid-react";

import RichTextCellEditor from "@components/Table/CellEditors/RichTextCellEditor";
import SimpleTextCellEditor from "@components/Table/CellEditors/SimpleTextCellEditor";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

import { ERequirementsTableColumn } from "../constants";

import styles from "./RequirementEditorCell.module.scss";

const RequirementEditorCell = memo((props: CustomCellEditorProps<IRequirementBlock, string>) => {
  const { data: requirementBlock, api, node, colDef, onValueChange } = props;
  const columnName = colDef.headerName as ERequirementsTableColumn;

  const getCellReturnValue = (): string => {
    switch (columnName) {
      case ERequirementsTableColumn.TITLE:
        return "Untitled Requirement";
      default:
        return "";
    }
  };

  const getCellDefaultValue = (): string => {
    switch (colDef.headerName) {
      case ERequirementsTableColumn.ID:
        return requirementBlock.computedVisibleId;
      case ERequirementsTableColumn.TITLE:
        return requirementBlock.label;
      case ERequirementsTableColumn.RATIONALE:
        return requirementBlock.rationale;
      case ERequirementsTableColumn.DESCRIPTION:
        return requirementBlock.description;
      case ERequirementsTableColumn.NOTES:
        return requirementBlock.note;
      default:
        return "";
    }
  };

  const handleCommit = () => {
    api.stopEditing();
  };

  const handleCancel = () => {
    api.stopEditing(true);
  };

  switch (columnName) {
    case ERequirementsTableColumn.TITLE:
    case ERequirementsTableColumn.DESCRIPTION:
    case ERequirementsTableColumn.RATIONALE:
    case ERequirementsTableColumn.NOTES:
      return (
        <RichTextCellEditor<IRequirementBlock>
          className={styles.requirementEditorCellRichTextCellEditor}
          node={node}
          api={api}
          defaultValue={getCellDefaultValue()}
          placeholder={getCellReturnValue()}
          onCommit={handleCommit}
          onCancel={handleCancel}
          onValueChange={onValueChange}
        />
      );
    default:
      return (
        <SimpleTextCellEditor<IRequirementBlock>
          className={styles.requirementEditorCellTextEditorCell}
          singleLine
          node={node}
          api={api}
          returnPlaceholder={getCellReturnValue()}
          defaultValue={getCellDefaultValue()}
          WrapperTag="div"
          onValueChange={onValueChange}
        />
      );
  }
});

RequirementEditorCell.displayName = "RequirementEditorCell";

export default RequirementEditorCell;
