import { useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";

// Hooks that scrolls the grid to a specific column when the grid columns change
export const useScrollGridToColumn = (gridApi?: GridApi) => {
  const [colIdToBeScrolledTo, setColIdToBeScrolledTo] = useState("");

  const handleGridColumnsChanged = useCallback(() => {
    const targetColumn = gridApi?.getColumns()?.find(column => column.getColId() === colIdToBeScrolledTo);
    if (targetColumn) {
      gridApi?.ensureColumnVisible(targetColumn, "end");
      setColIdToBeScrolledTo("");
    }
  }, [colIdToBeScrolledTo, gridApi, setColIdToBeScrolledTo]);

  useEffect(() => {
    gridApi?.addEventListener("gridColumnsChanged", handleGridColumnsChanged);
    return () => gridApi?.removeEventListener("gridColumnsChanged", handleGridColumnsChanged);
  }, [gridApi, handleGridColumnsChanged]);

  return setColIdToBeScrolledTo;
};
