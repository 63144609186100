import { useEffect, useState } from "react";
import { EditableText } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import styles from "./StatusTypeNumberEditor.module.scss";

type StatusTypeNumberEditorProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  hidePlaceholder?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): void;
};

function StatusTypeNumberEditor(props: StatusTypeNumberEditorProps) {
  const { statusDefinition, statusInstance, disabled, readOnly, hidePlaceholder, onDeleteStatusInstance, onAddStatusInstance } = props;
  // This should be calculated through the store.
  const inputTextDefaultValue = statusInstance?.value || "";

  const [inputText, setInputText] = useState(inputTextDefaultValue);

  useEffect(() => {
    setInputText(inputTextDefaultValue);
  }, [inputTextDefaultValue, statusInstance]);

  if (!statusDefinition) {
    return null;
  }

  if (readOnly) {
    return statusInstance?.value;
  }

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      onAddStatusInstance(statusDefinition, value);
    } else {
      if (!value) {
        onDeleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(value);
      }
    }
  };

  return (
    <div>
      <EditableText
        value={inputText}
        disabled={disabled}
        onChange={setInputText}
        onConfirm={() => setValue(inputText)}
        type="number"
        className={classNames(styles.statusTypeNumberEditor, { [styles.disabled]: disabled })}
        placeholder={hidePlaceholder ? "" : "Empty"}
      />
    </div>
  );
}

export type { StatusTypeNumberEditorProps };
export default observer(StatusTypeNumberEditor);
