import { ColDef, GetRowIdParams, ICellRenderer, ValueGetterParams, ValueSetterParams } from "ag-grid-community";

import BomTableTextCellEditor from "@components/BomTables/Table/Cells/BomTableTextCellEditor";
import {
  MentionsCellEditor,
  MultiplicityCell,
  NumberCellEditor,
  StatusTypeCellEditor,
} from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells";
import BlockStatusCell from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/BlockStatusCell";
import { BomColumnType, BomMetaColumn } from "@rollup-api/models/bom";
import appStore from "@store/AppStore";
import { BomTableNode, IBlock } from "@store/BlockStore";
import { IBomColumn } from "@store/BomTable/BomColumnStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { StatusType } from "@store/StatusDefinitionStore";
import { getSubtotalColumnValue, isCurrency } from "@utilities";

import AttachmentCell from "./Cells/AttachmentCell";
import ImageCell from "./Cells/ImageCell";
import PartNumberCellEditor from "./Cells/PartNumberCellEditor";
import ValueCell from "./Cells/ValueCell";
import { actionsColumnDef, bomTablePrefix, checkboxColumnDef, createNewColumnDef } from "./constants";

export const getCellRenderer = (columnType: BomColumnType, metaColumn: BomMetaColumn | null) => {
  if (columnType === BomColumnType.Status) {
    return BlockStatusCell;
  } else if (columnType === BomColumnType.Meta) {
    switch (metaColumn) {
      case BomMetaColumn.Thumbnail:
        return ImageCell;
      case BomMetaColumn.File:
        return AttachmentCell;
      case BomMetaColumn.Multiplicity:
        return MultiplicityCell;
    }
  }

  // hack the circle dependency error
  return ValueCell as unknown as ICellRenderer;
};

const getCellClass = (columnType: BomColumnType, metaColumn: BomMetaColumn | null, isNumeric: boolean) => {
  let cellClass = isNumeric ? "ag-right-aligned-cell " : "";

  if (columnType === BomColumnType.Status) {
    cellClass += "ag-status-cell";
  } else if (columnType === BomColumnType.Meta) {
    if (metaColumn === BomMetaColumn.Multiplicity) {
      cellClass += "ag-multiplicity-cell";
    } else if (metaColumn === BomMetaColumn.Thumbnail) {
      cellClass += "ag-image-cell";
    }
  } else {
    cellClass += "ag-value-cell";
  }

  return cellClass;
};

const getCellMinWidth = (columnType: BomColumnType, metaColumn: BomMetaColumn | null) => {
  if (columnType === BomColumnType.Meta) {
    switch (metaColumn) {
      case BomMetaColumn.Multiplicity:
        return 57;
    }
  }

  return 100;
};

const getCellWidth = (column: IBomColumn) => {
  if (column.columnType === BomColumnType.Meta) {
    switch (column.metaColumn) {
      case BomMetaColumn.Thumbnail:
        return 69;
    }
  }

  return column.width || undefined;
};

export const getCellEditor = (columnType: BomColumnType, metaColumn: BomMetaColumn | null, statusDefinitionType?: StatusType) => {
  if (columnType === BomColumnType.Status) {
    switch (statusDefinitionType) {
      case StatusType.multiSelect:
      case StatusType.singleSelect:
        return StatusTypeCellEditor;
      case StatusType.mention:
        return MentionsCellEditor;
      default:
        return null;
    }
  } else if (columnType === BomColumnType.Meta) {
    switch (metaColumn) {
      case BomMetaColumn.Multiplicity:
        return NumberCellEditor;
      case BomMetaColumn.PartNumber:
        return PartNumberCellEditor;
      case BomMetaColumn.Name:
      case BomMetaColumn.Notes:
        return BomTableTextCellEditor;
      default:
        return null;
    }
  }

  return null;
};

export const getCellEditable = (columnType: BomColumnType, metaColumn: BomMetaColumn | null) => {
  if (columnType === BomColumnType.Status) {
    return false;
  } else if (columnType === BomColumnType.Property) {
    return true;
  } else if (columnType === BomColumnType.Meta) {
    switch (metaColumn) {
      case BomMetaColumn.Notes:
      case BomMetaColumn.Name:
      case BomMetaColumn.Multiplicity:
      case BomMetaColumn.PartNumber:
        return true;
    }
  }

  return false;
};

const cellValueGetter = (node: ValueGetterParams<BomTableNode>, column: IBomColumn) => {
  if (node.data) {
    return column.getValue(node.data.block);
  }

  return "";
};

const cellValueSetter = (node: ValueSetterParams<BomTableNode>, column: IBomColumn) => {
  if (node.data) {
    column.setValue(node.newValue, node.data.block);
  }

  return true;
};

export const getRowId = (row: GetRowIdParams<BomTableNode>) => `${bomTablePrefix}-${row.data.block.id}`;

export const getColDefs = (columns: IBomColumn[]): ColDef<BomTableNode>[] => {
  const result: ColDef<BomTableNode>[] = [checkboxColumnDef, actionsColumnDef];
  const isLargeScreen = window.innerWidth > 1600;

  for (const column of columns) {
    const isThumbnailColumn = column.metaColumn === BomMetaColumn.Thumbnail;
    const suppressSizeToFit = isLargeScreen || !!column.width || isThumbnailColumn;

    result.push({
      suppressSizeToFit,
      flex: suppressSizeToFit ? undefined : 1,
      headerName: column.label,
      suppressColumnsToolPanel: true,
      colId: column.id,
      resizable: !isThumbnailColumn,
      width: getCellWidth(column),
      cellEditorPopup: column.columnType === BomColumnType.Status,
      singleClickEdit: true,
      minWidth: getCellMinWidth(column.columnType, column.metaColumn),
      editable: getCellEditable(column.columnType, column.metaColumn),
      cellClass: getCellClass(column.columnType, column.metaColumn, column.isNumeric),
      cellRenderer: getCellRenderer(column.columnType, column.metaColumn),
      cellEditor: getCellEditor(column.columnType, column.metaColumn, column.statusDefinition?.type),
      valueGetter: node => cellValueGetter(node, column),
      valueSetter: node => cellValueSetter(node, column),
      suppressMovable: column.suppressMovable,
      lockPosition: column.lockPosition,
      rowGroup: column.rowGroup,
      cellEditorParams: {
        projectStatusDefinition: column.statusDefinition,
        getValue: column.getValue,
      },
      cellRendererParams: {
        bomColumn: column,
        propertyDefinition: column.propertyDefinition,
        statusDefinition: column.statusDefinition,
      },
      headerComponentParams: {
        bomColumn: column,
        hideNav: column.metaColumn === BomMetaColumn.Name,
      },
    });
  }
  result.push(createNewColumnDef);
  return result;
};

// @todo remove `selections` check in a couple of weeks :)
export function getSelectionBlocks(block: IBlock, list: string[]) {
  list.push(block.id);

  if (block.children?.length) {
    for (const child of block.children) {
      getSelectionBlocks(child, list);
    }
  }
}

export const getTableTotal = (table: IBomTable): string => {
  const costDefinition = appStore.workspaceModel?.propertyDefinitions.find(p => p.label.toLowerCase() === "cost");

  if (costDefinition) {
    const totalValue = table.validRows.reduce((currentValue: number, block: IBlock) => {
      const value = getSubtotalColumnValue(block, true) as number;
      return value + currentValue;
    }, 0);

    return isCurrency(costDefinition.unit) ? `${totalValue} ${costDefinition.unit}` : `${totalValue}`;
  }

  return "0";
};
