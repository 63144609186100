import { FocusEvent, useCallback, useEffect } from "react";

import { IPopoverRefType } from "@components/Popover";
import StatusTypeMentionEditor from "@components/ProjectManagement/StatusTypeMentionEditor";
import appStore from "@store/AppStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import {
  EDITOR_MIN_HEIGHT,
  EDITOR_MIN_WIDTH,
  IStatusCellEditorParams,
  MENU_ITEM_CLASS,
  SELECT_INPUT_CLASS,
  TAG_REMOVE,
} from "./StatusTypeCellEditor";

export const MentionsCellEditor = (props: IStatusCellEditorParams) => {
  const actualWidth = props.column.getActualWidth();
  const width = actualWidth < EDITOR_MIN_WIDTH ? EDITOR_MIN_WIDTH : actualWidth;
  const { node, projectStatusDefinition } = props;
  const block = node?.data?.block;
  const statusInstance = block?.validatedStatusInstances.find(i => i.statusDefinition === projectStatusDefinition);

  useEffect(() => {
    const input = document.querySelector(`.ag-popup-editor input.${SELECT_INPUT_CLASS}`);
    (input as HTMLInputElement)?.focus();
  }, []);

  const handleBlur = (event: FocusEvent) => {
    const classList = event.relatedTarget?.classList;
    if (!classList?.contains(MENU_ITEM_CLASS) && !classList?.contains(SELECT_INPUT_CLASS) && !classList?.contains(TAG_REMOVE)) {
      props.stopEditing();
    }
  };

  const handleComponentReady = useCallback((popoverRef: React.RefObject<IPopoverRefType>) => {
    popoverRef.current?.setState({ isOpen: true });
  }, []);

  if (!block) {
    return null;
  }

  const handleOnAddStatusInstance = (statusDefinition: IStatusDefinition, value: string) => {
    appStore.workspaceModel?.addStatusInstance(block, statusDefinition, value);
  };

  return (
    <div style={{ width, minHeight: EDITOR_MIN_HEIGHT }} onBlur={handleBlur}>
      <StatusTypeMentionEditor
        onComponentReady={handleComponentReady}
        statusDefinition={projectStatusDefinition}
        statusInstance={statusInstance}
        onAddStatusInstance={handleOnAddStatusInstance}
      />
    </div>
  );
};

MentionsCellEditor.displayName = "MentionsCellEditor";

export default MentionsCellEditor;
