import { useEffect, useMemo } from "react";
import { useScrollGridToLastRow } from "@hooks/useScrollGridToLastRow";
import { useWorkspace } from "@hooks/useWorkspace";
import { Text, TextVariant } from "@ui/Text";
import {
  ColumnMovedEvent,
  GridReadyEvent,
  IsFullWidthRowParams,
  RowClassParams,
  RowClassRules,
  RowClickedEvent,
  RowDragEndEvent,
} from "ag-grid-community";
import { observer } from "mobx-react";

import { InputGroup } from "@components/InputGroup";
import { Loading } from "@components/Loading";
import RequirementPageNewRowButton from "@components/Requirements/Page/RequirementPageNewRowButton";
import { handleReqBlockClick } from "@components/Requirements/Page/RequirementPageUtils";
import HeadingCellRenderer from "@components/Requirements/RequirementsTable/Cells/HeadingCellRenderer";
import { useReqTableColumnDefs } from "@components/Requirements/RequirementsTable/useReqTableColumnDefs";
import Table from "@components/Table";
import { RequirementBlockType } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage, isHeading } from "@store/Requirements/RequirementsPageStore";

import { defaultColDef, ERequirementsTableColumn, HEADER_ROW_HEIGHT } from "./constants";
import { getRowDragText, getRowId, gridOptions } from "./utils";

import "./RequirementsTable.scss";
import styles from "./RequirementsTable.module.scss";

type TRequirementsProps = {
  rowData: IRequirementBlock[];
  reqPage: IRequirementsPage;
};

const rowClassRules: RowClassRules<IRequirementBlock> = {
  "requirements-table--locked-row": (params: RowClassParams<IRequirementBlock>) => !!params.data?.locked,
};
const isFullWidthRow = (params: IsFullWidthRowParams<IRequirementBlock>) => !!params.rowNode.data && isHeading(params.rowNode.data.type);
const handleRowClick = (e: RowClickedEvent<IRequirementBlock>) => {
  const { data: reqBlock } = e;

  if (reqBlock) {
    handleReqBlockClick(reqBlock);
  }
};

const RequirementsTable = (props: TRequirementsProps) => {
  const { reqPage } = props;
  const columnDefs = useReqTableColumnDefs(reqPage);
  const scrollToBottom = useScrollGridToLastRow(appStore.env.requirementsTableGridApi);
  const workspace = useWorkspace();
  const isLoading = !reqPage.columns.length;

  const fullWidthCellRendererParams = useMemo(() => ({ onDeleteRequirementBlock: reqPage.deleteRequirementBlock }), [reqPage]);

  useEffect(() => {
    if (!reqPage.columns.length) {
      reqPage.loadColumns();
    }
  }, [reqPage]);

  if (isLoading) {
    return <Loading />;
  }

  const handleAddRequirementsBlock = (type: RequirementBlockType) => {
    workspace.addRequirementsBlock(reqPage, type);
    scrollToBottom();
  };

  const handleColumnMoved = (e: ColumnMovedEvent<IRequirementBlock>) => {
    if (!e.finished) {
      return;
    }
    const columnsOrder = e.api.getAllGridColumns().map(c => c.getColId());
    columnsOrder.forEach((colId, index) => {
      const column = reqPage.columnMap.get(colId);
      if (column && index !== column.orderIndex) {
        column.setOrderIndex(index);
      }
    });
  };

  const handleGridReady = (event: GridReadyEvent<IRequirementBlock>) => {
    appStore.env.setRequirementsTableGridApi(event.api);
  };

  const onRowDragEnd = (e: RowDragEndEvent<IRequirementBlock>) => {
    const targetIndex: number = e.overIndex;
    const nodeId: string | undefined = e.node.data?.id;
    const targetId: string | undefined = props.rowData[targetIndex]?.id;

    if (nodeId && targetId) {
      reqPage.moveBlock(nodeId, targetId);
      e.api.refreshCells({ columns: [ERequirementsTableColumn.ID] });
    }
  };

  const handleColumnResize = (colId: string, width: number) => {
    const column = reqPage.columnMap.get(colId);
    column?.setWidth(width);
  };

  return (
    <div className={styles.requirementsTableContainer}>
      <div className={styles.requirementsTableHeader}>
        <div className={styles.requirementsTableDocNumberContainer}>
          <Text variant={TextVariant.Caption}>Document Number:</Text>
          <InputGroup
            className={styles.requirementsTableDocNumber}
            value={reqPage.docNumber}
            onChange={e => reqPage.setDocNumber(e.currentTarget.value)}
            e2eIdentifiers="doc-number"
            disableOutline
          />
        </div>
        <InputGroup
          className={styles.requirementsTableTitle}
          value={reqPage.label}
          onChange={e => reqPage.setLabel(e.currentTarget.value)}
          e2eIdentifiers="title"
          disableOutline
        />
      </div>
      <Table<IRequirementBlock>
        suppressMoveWhenRowDragging
        className="requirements-table"
        getRowId={getRowId}
        onRowDragEnd={onRowDragEnd}
        rowClassRules={rowClassRules}
        onRowClicked={handleRowClick}
        rowDragManaged
        suppressAnimationFrame
        animateRows={false}
        headerHeight={HEADER_ROW_HEIGHT}
        rowDragText={getRowDragText}
        onColumnResize={handleColumnResize}
        onColumnMoved={handleColumnMoved}
        defaultColDef={defaultColDef}
        onGridReady={handleGridReady}
        columnDefs={columnDefs}
        rowData={isLoading ? [] : props.rowData}
        isFullWidthRow={isFullWidthRow}
        fullWidthCellRenderer={HeadingCellRenderer}
        fullWidthCellRendererParams={fullWidthCellRendererParams}
        gridOptions={gridOptions}
        dynamicAutoHeight
      />
      <RequirementPageNewRowButton onAddRequirementsBlock={handleAddRequirementsBlock} />
    </div>
  );
};

export default observer(RequirementsTable);
