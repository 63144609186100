import { useState } from "react";
import { InputGroup, Menu } from "@blueprintjs/core";
import { useScrollGridToColumn } from "@hooks/useScrollGridToColumn";
import { CustomHeaderProps } from "ag-grid-react";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { PopupMenu } from "@components/Shared/PopupMenu";
import appStore from "@store/AppStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import styles from "./AddColumnHeaderRenderer.module.scss";

export interface IAddColumnHeaderRendererOwnProps {
  reqPageId: string;
  statusDefinitions: IStatusDefinition[];
  onAddStatusColumn(statusDefinitionId: string): string;
  onAddNewStatusDefinition(): Promise<IStatusDefinition | undefined>;
}

interface IAddColumnHeaderRendererProps extends IAddColumnHeaderRendererOwnProps, CustomHeaderProps {}

const AddColumnHeaderRenderer = (props: IAddColumnHeaderRendererProps) => {
  const { statusDefinitions, api, onAddStatusColumn, onAddNewStatusDefinition } = props;
  const [searchStatusValue, setSearchStatusValue] = useState("");
  const reqPage = appStore.workspaceModel?.requirementsModule.get(props.reqPageId);
  const scrollToColumn = useScrollGridToColumn(appStore.env.requirementsTableGridApi);

  if (!reqPage) {
    return null;
  }

  const filteredStatusDefinitions = statusDefinitions.filter(
    d => !searchStatusValue || d.label.toLowerCase().includes(searchStatusValue.toLowerCase())
  );

  const handleAddStatusColumn = async (id?: string) => {
    if (id) {
      setSearchStatusValue("");
      const colId = onAddStatusColumn(id);
      scrollToColumn(colId);
    } else {
      const statusDefinition = await onAddNewStatusDefinition();
      if (statusDefinition) {
        const colId = onAddStatusColumn(statusDefinition.id);
        scrollToColumn(colId);
      }
    }
  };

  const handleToggleColumn = (id: string) => {
    if (id) {
      const column = api.getColumn(id);
      if (column) {
        api.setColumnsVisible([column], !column.isVisible());
        if (column.isVisible()) {
          api.ensureColumnVisible(column, "end");
        }
      }
      reqPage.toggleColumn(id);
    }
  };

  const renderPopupMenuContent = () => {
    return (
      <Menu>
        <MenuItem icon="new-layer" text="Create new Status" onClick={() => handleAddStatusColumn()} e2eIdentifiers="add-new-status" />
        <MenuItem icon="new-layer" text="Add existing Status" e2eIdentifiers="add-existing-status">
          <div>Search</div>
          <InputGroup
            placeholder="Start typing name..."
            className="mb-2"
            rightElement={
              searchStatusValue ? (
                <Button minimal icon="cross" onClick={() => setSearchStatusValue("")} e2eIdentifiers="clear-search-status" />
              ) : undefined
            }
            leftIcon="search"
            value={searchStatusValue}
            onChange={e => setSearchStatusValue(e.target.value)}
          />
          <div className="create-new-column-nav--sub-menu">
            {filteredStatusDefinitions.map(d => (
              <MenuItem key={d.id} text={d.label} onClick={() => handleAddStatusColumn(d.id)} e2eIdentifiers={[d.label]} />
            ))}
          </div>
        </MenuItem>
        <MenuItem icon="new-layer" text="Columns" e2eIdentifiers="add-meta-column">
          {reqPage.orderedColumns.map(column => (
            <MenuItem
              roleStructure="listoption"
              selected={!column.hide}
              key={column.id}
              text={column.label}
              onClick={e => {
                e.stopPropagation();
                handleToggleColumn(column.id);
              }}
              e2eIdentifiers={["toggle", column.label]}
            />
          ))}
        </MenuItem>
      </Menu>
    );
  };

  return (
    <PopupMenu
      className={styles.addColumnHeaderRendererPopupMenu}
      content={renderPopupMenuContent()}
      buttonIcon="plus"
      e2eIdentifiers="open-add-column-menu"
    />
  );
};

export default observer(AddColumnHeaderRenderer);
