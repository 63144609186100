import { useState } from "react";
import { Intent, Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { Tag } from "@components/Tag";
import { LinkChannelDto, SlackChannel, SlackEventTypes } from "@rollup-api/models";
import { Text, TextVariant } from "src/ui/Text";

import SlackSettingsAddNewLink from "./SlackSettingsAddNewLink";

import "./SlackSubscriptionsList.scss";

type Props = {
  subscriptions: Array<LinkChannelDto>;
  channels: Array<SlackChannel>;
  onUnlink: (subscription: LinkChannelDto) => void;
  onUpdate: (channelId: string, eventTypes: SlackEventTypes[]) => void;
};

const SlackSubscriptionsList = (props: Props) => {
  const { subscriptions, channels, onUnlink, onUpdate } = props;
  const [editingSub, setEditingSub] = useState<LinkChannelDto | undefined>(undefined);
  const [itemToDelete, setItemToDelete] = useState<LinkChannelDto | null>(null);

  if (!subscriptions.length) {
    return null;
  }

  const getChannelName = (channelId: string) => {
    const channel = channels.find(c => c.id === channelId);
    if (!channel?.name) {
      return "Unknown channel";
    }
    return `#${channel.name}`;
  };

  const handleUnlink = () => {
    itemToDelete && onUnlink(itemToDelete);
    setItemToDelete(null);
  };

  const handleEditClick = (sub: LinkChannelDto) => {
    if (editingSub?.id === sub.id) {
      setEditingSub(undefined);
    } else {
      setEditingSub(sub);
    }
  };

  const handleUpdate = (dto: LinkChannelDto) => {
    onUpdate(dto.id, dto.entityTypes);
    setEditingSub(undefined);
  };

  const renderSubscription = (subscription: LinkChannelDto) => {
    if (editingSub?.id === subscription.id) {
      return (
        <SlackSettingsAddNewLink
          key={subscription.id}
          channels={channels}
          link={editingSub}
          onCancel={() => setEditingSub(undefined)}
          subscriptions={subscriptions}
          onSave={handleUpdate}
        />
      );
    }

    return (
      <div className="slack-subscription--row" key={subscription.id}>
        <div className="slack-subscription--cell slack-subscription--channel">{getChannelName(subscription.id)}</div>
        <div className="slack-subscription--cell slack-subscription--events">
          <span className="slack-subscription--events-title">
            {subscription.entityTypes.map(entity => (
              <Tag className="slack-subscription--events-title-tag" key={entity}>
                {entity}
              </Tag>
            ))}
          </span>
          <div>
            <Popover
              content={
                <Menu>
                  <MenuItem e2eIdentifiers="edit" icon="edit" text="Edit" onClick={() => handleEditClick(subscription)} />
                  <MenuItemDelete onDelete={() => setItemToDelete(subscription)} />
                </Menu>
              }
            >
              <Button minimal icon="more" e2eIdentifiers="subscription-menu" />
            </Popover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="slack-subscription">
      <div className="slack-subscription--header">
        <div className="slack-subscription--cell slack-subscription--channel">Channel</div>
        <div className="slack-subscription--cell">Event types</div>
      </div>
      {subscriptions.map(renderSubscription)}
      {itemToDelete && (
        <DialogLegacy
          isCloseButtonShown
          isOpen
          title={<Text variant={TextVariant.H3}>Delete "{getChannelName(itemToDelete.id)}" channel subscription?</Text>}
          onClose={() => setItemToDelete(null)}
        >
          <div className="slack-subscription--dialog">
            <Button
              className="slack-subscription--action"
              large
              minimal
              outlined
              onClick={() => setItemToDelete(null)}
              e2eIdentifiers="delete"
            >
              No
            </Button>
            <Button className="slack-subscription--action" large intent={Intent.PRIMARY} onClick={handleUnlink} e2eIdentifiers="cancel">
              Yes
            </Button>
          </div>
        </DialogLegacy>
      )}
    </div>
  );
};

export default observer(SlackSubscriptionsList);
