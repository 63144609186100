import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextVariant } from "@ui/Text";
import noop from "lodash/noop";
import { observer } from "mobx-react";

import { ProjectManagementIcon } from "@components/CustomIcon";
import { PlusButton } from "@components/PlusButton";
import { IconSource } from "@rollup-types/icons";
import { EPageName } from "@router/hooks/useAppNavigate";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";

import styles from "./SidePanelProjectList.module.scss";

interface IProject {
  id: string;
  label: string;
}

const mockProjects: IProject[] = [
  { id: "1", label: "Project 1" },
  { id: "2", label: "Project 2" },
  { id: "3", label: "Project 3" },
];

const SidePanelProjectList = () => {
  const renderProjectItem = (project: IProject) => {
    return (
      <NavLinkAsMenuItem
        className={styles.projectItem}
        icon={{ source: IconSource.Custom, name: ProjectManagementIcon.Project }}
        text={project.label}
        to={`/${EPageName.ProjectManagement}/${EPageName.ProjectManagementProjects}/${project.id}`}
        e2eIdentifiers={["project", project.label]}
        key={project.label}
        renderRightElements={className => <BlueprintIcon className={className} icon="chevron-right" />}
      />
    );
  };

  return (
    <div className={styles.sidePanelProjectList}>
      <div className={styles.header}>
        <Text variant={TextVariant.H5}>Your Projects</Text>
        {/* TODO implement in future */}
        <PlusButton onClick={noop} />
      </div>
      <div className={styles.projectsContainer}>{mockProjects.map(renderProjectItem)}</div>
    </div>
  );
};

export default observer(SidePanelProjectList);
