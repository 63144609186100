import { useEffect, useRef, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { Intent } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import HorizontalLayoutIcon from "@assets/icons/horizontal-layout.svg?react";
import { Button } from "@components/Button";
import AttachmentsCard from "@components/CatalogItemPreview/Components/AttachmentsCard";
import OverviewCard from "@components/CatalogItemPreview/Components/OverviewCard";
import RelationsCard from "@components/CatalogItemPreview/Components/RelationsCard";
import SourcingCard from "@components/CatalogItemPreview/Components/SourcingCard";
import SpecificationsCard from "@components/CatalogItemPreview/Components/SpecificationsCard";
import VersionsCard from "@components/CatalogItemPreview/Components/VersionsCard";
import { DragHandle } from "@components/DragHandle";
import appStore from "@store/AppStore";

import styles from "./CatalogItemPreview.module.scss";

const CatalogItemPreview = () => {
  const { catalogItemPreviewId, resetCatalogItemPreviewId, catalogItemReferencePreviewId, resetCatalogItemReferencePreviewId } =
    appStore.ui;
  const { isCatalogItemTableVerticalSplit, toggleCatalogItemTableVerticalSplit } = appStore.env;
  const catalogItemReference = appStore.orgModel.catalogItems.getCatalogItemReference(catalogItemReferencePreviewId);
  const catalogItem = catalogItemReferencePreviewId
    ? catalogItemReference?.referencedCatalogItem
    : appStore.orgModel.catalogItems.getCatalogItem(catalogItemPreviewId);
  const [activeVersion, setActiveVersion] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleLayout = () => {
    toggleCatalogItemTableVerticalSplit();
  };

  useEffect(() => {
    if (catalogItem?.latestVersion) {
      setActiveVersion(catalogItem.latestVersion.id);
    }
  }, [catalogItem, catalogItem?.versions.length]);

  if (!catalogItem || !isAlive(catalogItem)) {
    return null;
  }

  return (
    <div className={classNames(styles.catalogItemPreview, { [styles.catalogItemPreviewVertical]: isCatalogItemTableVerticalSplit })}>
      <DragHandle className={styles.catalogItemPreviewDragHandle} vertical={isCatalogItemTableVerticalSplit} />
      <div className={styles.catalogItemPreviewContent}>
        <div className={styles.catalogItemPreviewHeader}>
          <div className={styles.catalogItemPreviewHeaderLeft}>
            <BlueprintIcon icon="layers" />
            <span>{catalogItem.name}</span>
            <Button
              className={styles.catalogItemPreviewHeaderButton}
              intent={Intent.PRIMARY}
              text="Update item design"
              onClick={() => appStore.ui.setShowPdmNewVersionDialog(catalogItem.id)}
              e2eIdentifiers="new-version"
            />
          </div>
          <div className={styles.catalogItemPreviewHeaderRight}>
            <Button
              icon={isCatalogItemTableVerticalSplit ? <HorizontalLayoutIcon /> : "column-layout"}
              e2eIdentifiers="column-preview-catalog-item"
              minimal
              onClick={toggleLayout}
            />
            <Button
              icon="cross"
              e2eIdentifiers="close-catalog-item"
              minimal
              onClick={() => {
                resetCatalogItemPreviewId();
                resetCatalogItemReferencePreviewId();
              }}
            />
          </div>
        </div>
        <div className={styles.catalogItemPreviewBody} ref={containerRef}>
          <OverviewCard isVertical={isCatalogItemTableVerticalSplit} catalogItem={catalogItem} />
          <SpecificationsCard
            isVertical={isCatalogItemTableVerticalSplit}
            onVersionChange={setActiveVersion}
            activeVersion={activeVersion}
            catalogItem={catalogItem}
          />
          <SourcingCard isVertical={isCatalogItemTableVerticalSplit} activeVersion={activeVersion} />
          <AttachmentsCard isVertical={isCatalogItemTableVerticalSplit} onVersionChange={setActiveVersion} catalogItem={catalogItem} />
          <VersionsCard
            isVertical={isCatalogItemTableVerticalSplit}
            onVersionClick={setActiveVersion}
            activeVersion={activeVersion}
            catalogItem={catalogItem}
          />
          <ReactFlowProvider>
            <RelationsCard
              containerRef={containerRef}
              activeVersion={activeVersion}
              isVertical={isCatalogItemTableVerticalSplit}
              catalogItem={catalogItem}
            />
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  );
};

export default observer(CatalogItemPreview);
