import { cast, types } from "mobx-state-tree";

import { showToast } from "@components/UiLayers/toaster";

export type FeatureFlagDetails = {
  label: string;
  description?: string;
};

export type PrescribedFeatureFlag = {
  name: string;
  enabled: boolean;
  forceReload?: boolean;
};

export enum FeatureFlag {
  ARRAY_PROPERTY_TYPE = "arrayPropertyType",
  DEBUG_COPY_URL = "debugCopyUrl",
  INTERFACES = "interfaces",
  SUPPRESS_ERROR_TOASTS = "suppressErrorToasts",
  HOOPS_FLOATING_PART_VIEWER = "hoopsFloatingPartViewer",
  ROLLUP_EVENT_BELL = "rollupEventBell",
  BOARD_VIEW_DEBUG = "boardViewDebug",
  FORCE_ALL_INTEGRATIONS = "forceAllIntegrations",
  COPY_PASTE_BLOCKS = "copyPasteBlocks",
  CUSTOM_UNITS = "customUnits",
  THREE_D_VIEWER_CONFIGURATION = "3dViewerConfiguration",
  BOM = "bom",
  DEBUG_LOG_SOCKET_MESSAGES = "debugLogSocketMessages",
  SPREADSHEET_SUPPORT = "spreadsheetSupport",
  CATALOG_ITEMS_WORKSPACE_LEVEL = "catalogItemsWorkspaceLevel",
  PROJECT_MANAGEMENT = "projectManagement",
}

export const FEATURE_FLAG_DEFINITIONS: { [key in FeatureFlag]: FeatureFlagDetails } = {
  [FeatureFlag.ARRAY_PROPERTY_TYPE]: { label: "Array Property Type", description: "Enable the array property type." },
  [FeatureFlag.DEBUG_COPY_URL]: { label: "Debug Copy URL", description: "Enable the debug copy URL." },
  [FeatureFlag.INTERFACES]: { label: "Interfaces", description: "Enable the interfaces." },
  [FeatureFlag.SUPPRESS_ERROR_TOASTS]: {
    label: "Suppress Error Toasts",
    description: "Enable the suppress error toasts.",
  },
  [FeatureFlag.HOOPS_FLOATING_PART_VIEWER]: {
    label: "Hoops Floating Part Viewer",
    description: "Enable the Hoops floating part viewer.",
  },
  [FeatureFlag.ROLLUP_EVENT_BELL]: { label: "Rollup Event Bell", description: "Enable the rollup event bell." },
  [FeatureFlag.BOARD_VIEW_DEBUG]: { label: "Board View Debug", description: "Enable the board view debug." },
  [FeatureFlag.FORCE_ALL_INTEGRATIONS]: {
    label: "All integrations",
    description: "Force all integrations to be market as active.",
  },
  [FeatureFlag.COPY_PASTE_BLOCKS]: { label: "Copy/Paste Blocks", description: "Enable copy/paste blocks." },
  [FeatureFlag.CUSTOM_UNITS]: { label: "Custom Units", description: "Enable custom units." },
  [FeatureFlag.THREE_D_VIEWER_CONFIGURATION]: {
    label: "3d viewer configuration options",
    description: "Enable configuration options for 3D viewer.",
  },
  [FeatureFlag.BOM]: { label: "BOM", description: "Enable BOM." },
  [FeatureFlag.DEBUG_LOG_SOCKET_MESSAGES]: {
    label: "Debug Log Socket Messages",
    description: "Enable debug logging of all incoming socket messages.",
  },
  [FeatureFlag.SPREADSHEET_SUPPORT]: { label: "Spreadsheets", description: "Enable spreadsheet support." },
  [FeatureFlag.CATALOG_ITEMS_WORKSPACE_LEVEL]: {
    label: "Catalog Items Workspace Level",
    description: "Enable catalog items at the workspace level instead of org level",
  },
  [FeatureFlag.PROJECT_MANAGEMENT]: { label: "PM", description: "Enable Project Management." },
};

const FEATURE_FLAG_RELOAD_DELAY = 2000;

export const FeatureFlagsStore = types
  .model("FeatureFlags", {
    flags: types.array(types.string),
  })
  .volatile(() => ({
    fixedFlags: new Array<PrescribedFeatureFlag>(),
  }))
  .views(self => ({
    get flagMap() {
      const map = new Map<FeatureFlag, void>();
      for (const flag of self.flags) {
        if (FEATURE_FLAG_DEFINITIONS[flag as FeatureFlag]) {
          map.set(flag as FeatureFlag);
        }
      }
      return map;
    },
  }))
  .actions(self => ({
    setFlags(flags: string[]) {
      self.flags = cast(flags);
    },
    clearFlags() {
      self.flags = cast([]);
    },
    setFlag(flag: FeatureFlag, value: boolean) {
      // Skip invalid flags
      if (!FEATURE_FLAG_DEFINITIONS[flag as FeatureFlag]) {
        return;
      }

      // Adjust flags array directly, the map will get computed automatically
      if (value && !self.flagMap.has(flag)) {
        self.flags = cast([...self.flags, flag]);
      } else if (!value && self.flagMap.has(flag)) {
        self.flags = cast(self.flags.filter(f => f !== flag));
      }
    },
    toggleFlag(flag: FeatureFlag) {
      this.setFlag(flag, !self.flagMap.has(flag));
    },
  }))
  .views(self => ({
    enabled(flag: FeatureFlag) {
      return self.flagMap.has(flag);
    },
    canToggle(flag: FeatureFlag) {
      return self.fixedFlags.findIndex(f => f.name === flag) === -1;
    },
  }))
  .actions(self => ({
    enforceFlags(flags: PrescribedFeatureFlag[]) {
      let requireReload = false;
      for (const flag of flags) {
        const flagName = flag.name as FeatureFlag;
        // Skip invalid flags
        const flagDefinition = FEATURE_FLAG_DEFINITIONS[flagName];
        if (!flagDefinition) {
          continue;
        }

        console.debug(`Enforcing feature flag "${flagDefinition.label}" to ${flag.enabled ? "enabled" : "disabled"}`);
        if (self.enabled(flagName) !== flag.enabled) {
          self.setFlag(flagName, flag.enabled);
          requireReload ||= flag.forceReload ?? false;
        }
      }
      if (requireReload) {
        showToast("Feature flags have been updated. Reloading...");
        setTimeout(() => {
          window.location.reload();
        }, FEATURE_FLAG_RELOAD_DELAY);
      }
      self.fixedFlags = flags;
    },
  }));
