import { JSX, ReactNode } from "react";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import { Tooltip } from "@components/Tooltip";
import { LabeledEntity } from "@store/WorkspaceStore";
import { createCopyToClipboardHandler } from "@utilities/TextUtil";

import "./ModulePageHeader.scss";

type ModulePageHeaderProps<T extends LabeledEntity> = {
  entity: T;
  unsaved?: boolean;
  showLink?: boolean;
  contextMenu?: JSX.Element;
  rightActions?: JSX.Element;
  moreActions?: JSX.Element;
  entityName?: string;
  elementByTheTitle?: ReactNode;
  rightElement?: ReactNode;
};

function ModulePageHeader<T extends LabeledEntity>({
  entity,
  rightActions,
  moreActions,
  contextMenu,
  entityName = "entity",
  unsaved,
  showLink,
  elementByTheTitle,
  rightElement,
}: ModulePageHeaderProps<T>) {
  const { label, docNumber } = entity;

  const handleCopyToClipboard = createCopyToClipboardHandler(window.location.href, `${entityName} link`);
  return (
    <div className="module-page-header">
      <div className="module-page-header--left-container">
        <div className="module-page-header--title">
          <div className="module-page-header--title-text">
            {`${label}${docNumber ? ` (ID:${docNumber})` : ""}`}
            {unsaved && <span className="module-page-header--title-unsaved-indicator" />}
          </div>
          {contextMenu && (
            <MoreOptionsMenu
              buttonProps={{ className: "module-page-header--title-menu-btn" }}
              content={contextMenu}
              e2eIdentifiers="open-title-options-menu"
            />
          )}
        </div>
        {elementByTheTitle}
      </div>
      <div className="module-page-header--actions">
        {rightActions}
        {showLink && (
          <Tooltip content="Copy link to clipboard">
            <Button icon="link" e2eIdentifiers="copy-to-clipboard" onClick={handleCopyToClipboard} minimal />
          </Tooltip>
        )}
        {moreActions && <MoreOptionsMenu content={moreActions} e2eIdentifiers="show-menu" />}
        {rightElement}
      </div>
    </div>
  );
}

export default observer(ModulePageHeader);
