import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextMenu } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import SpreadsheetIcon from "@assets/icons/analysis/spreadsheet.svg?react";
import { ExecutionTypeIcon } from "@components/Analysis";
import DynamicLabelInput from "@components/DynamicLabelInput/DynamicLabelInput";
import { FavoriteButton } from "@components/FavoriteButton";
import MoreOptionsMenu from "@components/MoreOptionsMenu/MoreOptionsMenu";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import { AnalysisType, IAnalysis } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { EntityType } from "@store/types";
import { closePopover } from "@utilities/DomUtils";

import AnalysisSidebarContextMenu from "../AnalysisSidebarContextMenu/AnalysisSidebarContextMenu";

import styles from "./AnalysisSidebarItem.module.scss";

interface AnalysisSidebarItemProps {
  analysis: IAnalysis;
  minimal?: boolean;
}

const AnalysisSidebarItem = (props: AnalysisSidebarItemProps) => {
  const { analysis, minimal } = props;
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openCodeBlock = (codeBlockId: string) => {
    navigate(`${codeBlockId}`);
  };

  const renderRightElements = (className: string) => {
    return (
      <div className={classNames(className, { [styles.codeBlocksSidebarItemPopoverOpen]: isPopoverOpen })}>
        <FavoriteButton entityId={analysis.id} entityType={EntityType.CodeBlock} />
        <MoreOptionsMenu
          content={<AnalysisSidebarContextMenu onEnter={() => setIsPopoverOpen(false)} analysis={analysis} />}
          popoverProps={{ isOpen: isPopoverOpen, onInteraction: setIsPopoverOpen, placement: "bottom-end" }}
          e2eIdentifiers={analysis.label}
          small
        />
      </div>
    );
  };

  const renderText = () => {
    if (minimal) {
      return undefined;
    }

    return (
      <DynamicLabelInput
        className={classNames("reports-group-cell--name", "cell-title")}
        isEditing={isEditing}
        value={analysis.label}
        onUpdate={analysis.setLabel}
        onStopEditing={() => setIsEditing(false)}
      />
    );
  };

  const analysisIcon = () => {
    if (analysis.analysisType === AnalysisType.Spreadsheet) {
      return <SpreadsheetIcon />;
    }
    return <ExecutionTypeIcon type={analysis.type} />;
  };

  return (
    <ContextMenu
      key={analysis.id}
      onContextMenu={() => openCodeBlock(analysis.id)}
      content={<AnalysisSidebarContextMenu onEnter={closePopover} analysis={analysis} />}
      onDoubleClick={() => setIsEditing(true)}
    >
      <NavLinkAsMenuItem
        to={`${analysis.id}`}
        text={renderText()}
        icon={analysisIcon()}
        selected={appStore.env.activeAnalysisId === analysis.id}
        e2eIdentifiers={["code-blocks_sidebar", analysis.label]}
        renderRightElements={minimal ? undefined : renderRightElements}
      />
    </ContextMenu>
  );
};

export default observer(AnalysisSidebarItem);
