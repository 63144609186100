import { NonIdealState } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import CollapsibleSection from "@components/Shared/CollapsibleSection/CollapsibleSection";
import DismissibleCallout from "@components/Shared/DismissibleCallout/DismissibleCallout";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";
import { IAnalysis } from "@store/Analysis/AnalysisStore";

import AnalysisInputListItem from "./AnalysisInputListItem";
import AnalysisOutputListItem from "./AnalysisOutputListItem";

import styles from "./AnalysisInputOutputSidebar.module.scss";

type AnalysisInputOutputSidebarProps = {
  analysis: IAnalysis;
};

function AnalysisInputOutputSidebar({ analysis }: AnalysisInputOutputSidebarProps) {
  const inputs = analysis.sortedInputs;
  const outputs = analysis.sortedOutputs;

  if (analysis.type !== ExecutionEnvironmentType.Python) {
    return (
      <div className={styles.analysisInputOutputSidebar}>
        <NonIdealState icon="variable" title="Not supported" description="Inputs and outputs are only supported in Python blocks." />
      </div>
    );
  }

  return (
    <div className={styles.analysisInputOutputSidebar}>
      <CollapsibleSection
        defaultOpen
        title="Input"
        action={{ icon: "add", label: "Add new input", onClick: analysis.addNewInput, e2eIdentifiers: "action-add-new-analysis-input" }}
      >
        <>
          {inputs.length ? (
            inputs.map(input => <AnalysisInputListItem key={input.id} analysisInput={input} />)
          ) : (
            <NonIdealState title="No inputs" description="Add inputs to this analysis." />
          )}
        </>
      </CollapsibleSection>
      <CollapsibleSection
        defaultOpen
        title="Output"
        action={{
          icon: "add",
          label: "Add new output",
          onClick: analysis.addNewOutput,
          e2eIdentifiers: "action-add-new-analysis-output",
        }}
      >
        <>
          {outputs.length ? (
            outputs.map(output => <AnalysisOutputListItem key={output.id} analysisOutput={output} />)
          ) : (
            <NonIdealState title="No outputs" description="Add outputs to this analysis." />
          )}
        </>
      </CollapsibleSection>
      <div className={styles.spacer} />
      <DismissibleCallout intent="warning" icon="info-sign" calloutKey="analysis-io-limitations" title="Experimental">
        <Text variant={TextVariant.Body}>
          End-to-end code execution is currently in development. Output values from code execution can not be used in property expressions
          yet.
        </Text>
      </DismissibleCallout>
    </div>
  );
}

export default observer(AnalysisInputOutputSidebar);
