import { MouseEvent } from "react";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { InputGroup } from "@components/InputGroup";
import RequirementPageNewRowButton from "@components/Requirements/Page/RequirementPageNewRowButton";
import { SortableLinkedLists } from "@components/Shared/SortableLinkedLists";
import { TItemRendererArgs } from "@components/Shared/SortableLinkedLists/Components/Item";
import { useDragToSelect } from "@providers/DragToSelect";
import { RequirementBlockType } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";

import { RequirementsDocumentBlock } from "./RequirementsDocumentBlock";

import styles from "./RequirementsDocumentView.module.scss";

interface IRequirementsDocumentViewProps {
  reqPage: IRequirementsPage;
}

const REQUIREMENTS_CONTAINER_ID = "REQUIREMENTS_CONTAINER_ID";

const RequirementsDocumentView = (props: IRequirementsDocumentViewProps) => {
  const { reqPage } = props;
  const workspace = useWorkspace();
  const { resetSelection, elementsContainerRef } = useDragToSelect({
    onDelete: appStore.workspaceModel?.deleteRequirementBlocks,
    allIds: reqPage.allValidBlockIds,
  });

  const handlePageMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const isEmptySpaceClick = [REQUIREMENTS_CONTAINER_ID].includes((e.target as HTMLElement).id);
    if (isEmptySpaceClick && appStore.ui.selectedIds.length) {
      appStore.ui.setSelectedIds([]);
    }
  };

  const handleMoveBlocks = (ids: string[], targetId: string) => {
    if (ids.length === 1) {
      reqPage.moveBlock(ids[0], targetId);
    }
  };

  const handleAddRequirementsBlock = (type: RequirementBlockType) => {
    workspace.addRequirementsBlock(reqPage, type);
  };

  const renderItem = (params: TItemRendererArgs) => {
    const { index, listeners, handleProps, value: reqBlockId } = params;
    const reqBlock = appStore.workspaceModel?.requirementsModule.getBlock(reqBlockId);

    if (!reqBlock) {
      return null;
    }

    return (
      <RequirementsDocumentBlock
        reqBlock={reqBlock}
        reqPage={reqPage}
        onFocus={resetSelection}
        dragListeners={{ ...listeners, ...handleProps }}
        key={`${reqBlockId}--${index}`}
      />
    );
  };

  return (
    <div id={REQUIREMENTS_CONTAINER_ID} onMouseDown={handlePageMouseDown} className={styles.requirementsDocumentView}>
      <div className={styles.requirementsDocumentViewInner} data-dragselectable={false}>
        <div className={styles.topContainer}>
          <InputGroup
            className={styles.title}
            value={reqPage.label}
            onChange={e => reqPage.setLabel(e.currentTarget.value)}
            e2eIdentifiers="title"
            disableOutline
          />
          <InputGroup
            className={styles.docNumber}
            value={reqPage.docNumber}
            onChange={e => reqPage.setDocNumber(e.currentTarget.value)}
            e2eIdentifiers="doc-number"
            disableOutline
          />
        </div>
        <SortableLinkedLists
          noGap
          placeholder={() => null}
          defaultSelectedIds={[...appStore.ui.selectedIds]}
          wrapperRef={elementsContainerRef}
          multiSelect
          onMultipleItemsDragEnd={handleMoveBlocks}
          renderItem={renderItem}
          items={{ "main-report-block": reqPage.validatedBlockIds }}
        />
        <RequirementPageNewRowButton
          className={styles.requirementsDocumentViewNewRowButton}
          onAddRequirementsBlock={handleAddRequirementsBlock}
        />
      </div>
    </div>
  );
};

export default observer(RequirementsDocumentView);
