import axios from "axios";

import { BillingModule } from "@rollup-api/api/billing/billingModule";
import { NotificationSettings } from "@rollup-api/api/notificationSettings";
import { Subscriptions } from "@rollup-api/api/subscriptions";
import { TableColumns } from "@rollup-api/api/tableColumns";
import { WorkspaceSettings } from "@rollup-api/api/workspaceSettings";

import { AnalysisModule } from "./api/analysis";
import { Annotations } from "./api/annotations";
import { ApiKeys } from "./api/apiKeys";
import { Attributes } from "./api/attributes";
import { Auth } from "./api/auth";
import { BomTables } from "./api/bomTables";
import { CatalogItemReferences } from "./api/catalogItemReferences";
import { CatalogItems } from "./api/catalogItems";
import { CatalogItemVersions } from "./api/catalogItemVersions";
import { CloudStorage } from "./api/cloudStorage";
import { Comments } from "./api/comments";
import { Favorites } from "./api/favorites";
import { GeneralInfo } from "./api/generalInfo";
import { Imports } from "./api/imports";
import { Integrations } from "./api/integrations";
import { Interfaces } from "./api/interfaces";
import { Invitations } from "./api/invitations";
import { LiveLinks } from "./api/liveLinks";
import { ModelingModule } from "./api/modeling";
import { OauthApp } from "./api/oauthApp";
import { Organizations } from "./api/organizations";
import { OrganizationSettings } from "./api/organizationSettings";
import { PartNumberSchemas } from "./api/partNumberSchemas";
import { PmTags } from "./api/pmTags";
import { Profiles } from "./api/profiles";
import { ReportBlocks } from "./api/reportBlocks";
import { Reports } from "./api/reports";
import { RequirementBlocks } from "./api/requirementBlocks";
import { RequirementsPages } from "./api/requirementsPages";
import { Revisions } from "./api/revisions";
import { RollupEvents } from "./api/rollupEvents";
import { StatusDefinitions } from "./api/statusDefinitions";
import { StatusInstances } from "./api/statusInstances";
import { StatusOptions } from "./api/statusOptions";
import { Strapi } from "./api/strapi";
import { TableViewConfigs } from "./api/tableViewConfigs";
import { Transactions } from "./api/transactions";
import { Views } from "./api/view";
import { Webhooks } from "./api/webhooks";
import { Workspaces } from "./api/workspaces";
import { ParentClient } from "./api";

export interface RollupClientInfo {
  name: string;
  env: string;
  database: string;
  time: string;
  host: string;
  version?: string;
}

export class RollupClient implements ParentClient {
  private endpointInfo: RollupClientInfo | undefined;
  public workspaceId?: string;
  public readonly apiPrefix = "/core";

  // Modules with nested API endpoints
  public readonly analysisModule: AnalysisModule;
  public readonly billingModule: BillingModule;
  public readonly modelingModule: ModelingModule;

  // Other API endpoints
  public readonly auth: Auth;
  public readonly favorites: Favorites;
  public readonly strapi: Strapi;
  public readonly generalInfo: GeneralInfo;
  public readonly organizations: Organizations;
  public readonly invitations: Invitations;
  public readonly transactions: Transactions;
  public readonly apiKeys: ApiKeys;
  public readonly partNumberSchemas: PartNumberSchemas;
  public readonly webhooks: Webhooks;
  public readonly oauthApp: OauthApp;
  public readonly profiles: Profiles;
  public readonly workspaces: Workspaces;
  public readonly revisions: Revisions;
  public readonly reports: Reports;
  public readonly reportBlocks: ReportBlocks;
  public readonly organizationSettings: OrganizationSettings;
  public readonly statusDefinitions: StatusDefinitions;
  public readonly statusInstances: StatusInstances;
  public readonly statusOptions: StatusOptions;
  public readonly interfaces: Interfaces;
  public readonly attachments: CloudStorage;
  public readonly attributes: Attributes;
  public readonly comments: Comments;
  public readonly annotations: Annotations;
  public readonly views: Views;
  public readonly requirementsPages: RequirementsPages;
  public readonly requirementBlocks: RequirementBlocks;
  public readonly tableViewConfigs: TableViewConfigs;
  public readonly pmTags: PmTags;
  public readonly bomTables: BomTables;
  public readonly integrations: Integrations;
  public readonly rollupEvents: RollupEvents;
  public readonly liveLinks: LiveLinks;
  public readonly imports: Imports;
  public readonly catalogItems: CatalogItems;
  public readonly catalogItemVersions: CatalogItemVersions;
  public readonly catalogItemReferences: CatalogItemReferences;
  public readonly subscriptions: Subscriptions;
  public readonly notificationSettings: NotificationSettings;
  public readonly tableColumns: TableColumns;
  public readonly workspaceSettings: WorkspaceSettings;

  get info() {
    return this.endpointInfo;
  }

  constructor(public readonly url: string) {
    this.analysisModule = new AnalysisModule(this);
    this.annotations = new Annotations(this);
    this.apiKeys = new ApiKeys(this);
    this.attachments = new CloudStorage(this);
    this.attributes = new Attributes(this);
    this.auth = new Auth(this);
    this.billingModule = new BillingModule(this);
    this.bomTables = new BomTables(this);
    this.catalogItemVersions = new CatalogItemVersions(this);
    this.catalogItemReferences = new CatalogItemReferences(this);
    this.catalogItems = new CatalogItems(this);
    this.comments = new Comments(this);
    this.generalInfo = new GeneralInfo({ ...this, apiPrefix: "" });
    this.favorites = new Favorites(this);
    this.imports = new Imports(this);
    this.integrations = new Integrations(this);
    this.interfaces = new Interfaces(this);
    this.invitations = new Invitations(this);
    this.liveLinks = new LiveLinks(this);
    this.modelingModule = new ModelingModule(this);
    this.oauthApp = new OauthApp(this);
    this.organizationSettings = new OrganizationSettings(this);
    this.organizations = new Organizations(this);
    this.partNumberSchemas = new PartNumberSchemas(this);
    this.profiles = new Profiles(this);
    this.reportBlocks = new ReportBlocks(this);
    this.reports = new Reports(this);
    this.requirementBlocks = new RequirementBlocks(this);
    this.requirementsPages = new RequirementsPages(this);
    this.revisions = new Revisions(this);
    this.rollupEvents = new RollupEvents(this);
    this.statusDefinitions = new StatusDefinitions(this);
    this.statusInstances = new StatusInstances(this);
    this.statusOptions = new StatusOptions(this);
    this.strapi = new Strapi(this);
    this.subscriptions = new Subscriptions(this);
    this.tableViewConfigs = new TableViewConfigs(this);
    this.pmTags = new PmTags(this);
    this.transactions = new Transactions(this);
    this.url = url;
    this.views = new Views(this);
    this.webhooks = new Webhooks(this);
    this.workspaces = new Workspaces(this);
    this.notificationSettings = new NotificationSettings(this);
    this.tableColumns = new TableColumns(this);
    this.workspaceSettings = new WorkspaceSettings(this);

    this.fetchInfo();
  }

  private fetchInfo = async () => {
    try {
      const res = await axios.get<RollupClientInfo>(this.url + "/info");
      if (res.status === 200 && res.data?.name) {
        this.endpointInfo = { ...res.data, host: this.url.replace(/https*:\/\//, "") };
        return true;
      } else {
        console.warn("Error fetching API info");
      }
    } catch (err) {
      console.warn(`Error fetching API info: ${err}`);
    }
    this.endpointInfo = undefined;
    return false;
  };

  public setWorkspaceId = (id: string) => {
    this.workspaceId = id;
  };
}
