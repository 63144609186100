import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Icon } from "@components/Icon";
import { useAppNavigate } from "@router/hooks";
import { IBlock } from "@store/BlockStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { getPropertyInstanceById } from "@utilities";

import styles from "./LinkedBlockButton.module.scss";

interface ILinkedBlockButtonProps {
  reqBlock: IRequirementBlock;
  outlined?: boolean;
  className?: string;
  noneClassName?: string;
}

const LinkedBlockButton = (props: ILinkedBlockButtonProps) => {
  const { reqBlock, outlined, className, noneClassName } = props;
  const { navigateToBlock } = useAppNavigate();

  const linkedProperty = getPropertyInstanceById(reqBlock.linkedProperty);

  if (!linkedProperty) {
    return <div className={noneClassName}>None</div>;
  }

  const parentBlock = linkedProperty.parentBlock as IBlock;

  return (
    <Button
      className={classNames(styles.linkedBlockButton, className)}
      icon={<Icon icon={parentBlock.iconView} />}
      onClick={() => navigateToBlock(parentBlock.id)}
      e2eIdentifiers="navigate-to-linked-block"
      minimal
      outlined={outlined}
    >
      <span>{linkedProperty.parentBlock.label}</span>
    </Button>
  );
};

export default observer(LinkedBlockButton);
