import { useMemo } from "react";
import { useWorkspace } from "@hooks/useWorkspace";
import { CellClassParams, ColDef, EditableCallbackParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { isAlive } from "mobx-state-tree";

import RequirementEditorCell from "@components/Requirements/RequirementsTable/Cells/RequirementEditorCell";
import RequirementsStatusCell, {
  IRequirementsStatusCellOwnProps,
} from "@components/Requirements/RequirementsTable/Cells/RequirementsStatusCell";
import { ERequirementsTableColumn } from "@components/Requirements/RequirementsTable/constants";
import { TextCellRenderer } from "@components/Table/CellRenderers/TextCellRenderer";
import { ITextCellRendererOwnProps } from "@components/Table/CellRenderers/TextCellRenderer/TextCellRenderer";
import { CommentCellRenderer } from "@components/Table/Components/CommentCellRenderer";
import {
  ICommentCellRendererParams,
  ICommentCellRendererValue,
} from "@components/Table/Components/CommentCellRenderer/CommentCellRenderer";
import { CommentHeader } from "@components/Table/HeaderComponents/CommentHeader";
import { IEntityData } from "@rollup-api/models/table-column/creteTableColumnDto";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";
import { ITableColumn } from "@store/Requirements/TableColumnStore";
import { EntityType } from "@store/types";
import { IWorkspace } from "@store/WorkspaceStore";
import { isDefined } from "@utilities/TypeGuards";

import { AddColumnHeaderRenderer } from "./Cells/AddColumnHeaderRenderer";
import { IAddColumnHeaderRendererOwnProps } from "./Cells/AddColumnHeaderRenderer/AddColumnHeaderRenderer";
import { IRequirementsActionsCellOwnProps, RequirementsActionsCell } from "./Cells/RequirementsActionsCell";
import { IRequirementsHeaderCellRendererOwnProps } from "./Cells/RequirementsHeaderCellRenderer/RequirementsHeaderCellRenderer";
import {
  descriptionCellRenderer,
  functionalTypeCellRenderer,
  levelCellRenderer,
  linkedBlockCellRenderer,
  linkedPropertyCellRenderer,
  methodCellRenderer,
  notesCellRenderer,
  rationaleCellRenderer,
  successCriteriaCellRenderer,
  titleCellRenderer,
  verificationCellRenderer,
  verificationStatusCellRenderer,
} from "./utils";

import styles from "./RequirementsTable.module.scss";

const getActionsColumnDef = (reqPage: IRequirementsPage): ColDef<IRequirementBlock> => ({
  colId: ERequirementsTableColumn.ACTIONS,
  headerName: "",
  pinned: "left",
  lockPosition: "left" as const,
  resizable: false,
  width: 25,
  maxWidth: 25,
  suppressSizeToFit: true,
  cellRenderer: RequirementsActionsCell,
  cellRendererParams: { onDeleteRequirementBlock: reqPage.deleteRequirementBlock } as IRequirementsActionsCellOwnProps,
});

export const getAddNewColumnDef = (reqPage: IRequirementsPage, workspace: IWorkspace): ColDef<IRequirementBlock> => ({
  width: 50,
  lockPosition: "right" as const,
  pinned: "right",
  headerComponent: AddColumnHeaderRenderer,
  headerClass: styles.requirementsTableAddColumnHeader,
  suppressSizeToFit: true,
  headerComponentParams: {
    reqPageId: reqPage.id,
    statusDefinitions: workspace.statusDefinitions.filter(statusDefinition => !reqPage.hasStatusColumn(statusDefinition.id)),
    onAddStatusColumn: reqPage.addStatusColumn,
    onAddNewStatusDefinition: workspace.addNewStatusDefinition,
  } satisfies IAddColumnHeaderRendererOwnProps,
});

const getMetaColumnDef = (
  metaColumn: ERequirementsTableColumn,
  column: ITableColumn,
  reqPage: IRequirementsPage
): ColDef<IRequirementBlock> | undefined => {
  switch (metaColumn) {
    case ERequirementsTableColumn.ID:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 100,
        hide: column.hide,
        minWidth: 100,
        lockPosition: "left" as const,
        colId: column.id,
        headerName: ERequirementsTableColumn.ID,
        cellRenderer: TextCellRenderer,
        cellRendererParams: { className: styles.requirementsTableIdCellRenderer } satisfies ITextCellRendererOwnProps,
        cellEditor: RequirementEditorCell,
        cellClassRules: {
          "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
        },
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => {
          if (isAlive(cellProps.data)) {
            return cellProps.data?.computedVisibleId ?? "";
          }
        },
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setVisibleId(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.LEVEL:
      return {
        width: column.width ?? 70,
        hide: column.hide,
        minWidth: 70,
        lockPosition: "left" as const,
        colId: column.id,
        headerName: ERequirementsTableColumn.LEVEL,
        cellRenderer: levelCellRenderer,
        cellClass: "select-cell-wrapper",
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.TITLE:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 140,
        hide: column.hide,
        minWidth: 140,
        lockPosition: "left" as const,
        suppressMovable: true,
        colId: column.id,
        headerName: ERequirementsTableColumn.TITLE,
        cellEditor: RequirementEditorCell,
        cellRenderer: titleCellRenderer,
        cellClassRules: {
          "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
        },
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.label ?? "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setLabel(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.FUNCTIONAL_TYPE:
      return {
        width: column.width ?? 110,
        minWidth: 110,
        hide: column.hide,
        headerName: ERequirementsTableColumn.FUNCTIONAL_TYPE,
        colId: column.id,
        cellRenderer: functionalTypeCellRenderer,
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.COMMENTS:
      return {
        width: 44,
        headerName: "",
        headerComponent: CommentHeader,
        colId: column.id,
        resizable: false,
        cellRenderer: CommentCellRenderer,
        cellRendererParams: {
          lastFocusedCommentId: reqPage.lastFocusedCommentId,
          onFocusComment: reqPage.setLastFocusedCommentId,
          getEntityIdsWithComments: () => reqPage.getReqBlockIdsWithComments(true),
        } satisfies ICommentCellRendererParams,
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        valueGetter: (params: ValueGetterParams<IRequirementBlock, ICommentCellRendererValue>) => {
          return {
            entityId: params.data?.id ?? "",
            annotationList: params.data?.annotationList,
          } satisfies ICommentCellRendererValue;
        },
      };
    case ERequirementsTableColumn.RATIONALE:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 130,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.RATIONALE,
        cellRenderer: rationaleCellRenderer,
        cellClassRules: {
          "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
        },
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        cellEditor: RequirementEditorCell,
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.rationale ?? "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setRationale(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.DESCRIPTION:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 130,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.DESCRIPTION,
        cellRenderer: descriptionCellRenderer,
        cellClassRules: {
          "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
        },
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        cellEditor: RequirementEditorCell,
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.description ?? "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setDescription(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.LINKED_BLOCK:
      return {
        width: column.width ?? 100,
        hide: column.hide,
        minWidth: 100,
        colId: column.id,
        headerName: ERequirementsTableColumn.LINKED_BLOCK,
        cellRenderer: linkedBlockCellRenderer,
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.LINKED_PROPERTY:
      return {
        width: column.width ?? 180,
        hide: column.hide,
        minWidth: 100,
        colId: column.id,
        headerName: ERequirementsTableColumn.LINKED_PROPERTY,
        cellRenderer: linkedPropertyCellRenderer,
        cellClass: "linked-property-cell--wrapper",
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.METHOD:
      return {
        width: column.width ?? 120,
        hide: column.hide,
        minWidth: 120,
        colId: column.id,
        headerName: ERequirementsTableColumn.METHOD,
        cellRenderer: methodCellRenderer,
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.VERIFICATION:
      return {
        width: column.width ?? 160,
        hide: column.hide,
        minWidth: 160,
        colId: column.id,
        headerName: ERequirementsTableColumn.VERIFICATION,
        cellRenderer: verificationCellRenderer,
        cellClass: styles.requirementsTableNoPaddingCellWrapper,
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.SUCCESS_CRITERIA:
      return {
        width: column.width ?? 130,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.SUCCESS_CRITERIA,
        cellRenderer: successCriteriaCellRenderer,
        cellClass: "select-cell-wrapper",
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.VERIFICATION_STATUS:
      return {
        width: column.width ?? 120,
        hide: column.hide,
        minWidth: 120,
        colId: column.id,
        headerName: ERequirementsTableColumn.VERIFICATION_STATUS,
        cellRenderer: verificationStatusCellRenderer,
        cellClass: styles.requirementsTableNoPaddingCellWrapper,
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      };
    case ERequirementsTableColumn.NOTES:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 180,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.NOTES,
        cellRenderer: notesCellRenderer,
        cellClassRules: {
          "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
        },
        headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        cellEditor: RequirementEditorCell,
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.note || "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setNote(cellProps.newValue);
          return true;
        },
      };
  }
};

const getStatusColumnDef = (entity: IEntityData, column: ITableColumn, reqPage: IRequirementsPage): ColDef<IRequirementBlock> => {
  const statusDefinition = appStore.workspaceModel?.statusDefinitionMap.get(entity.id);
  return {
    width: column.width ?? 140,
    hide: column.hide,
    minWidth: 100,
    colId: column.id,
    headerName: statusDefinition?.label ?? "",
    cellRenderer: RequirementsStatusCell,
    cellRendererParams: { statusDefinition } as IRequirementsStatusCellOwnProps,
    headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
    cellClassRules: {
      "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
    },
    valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => {
      if (entity.type === EntityType.StatusInstance) {
        return cellProps.data?.getStatusInstanceValue(entity.id) ?? "";
      }
    },
  };
};

const mapTableColumnToColumnDef = (column: ITableColumn, reqPage: IRequirementsPage): ColDef<IRequirementBlock> | undefined => {
  if (column.entity) {
    return getStatusColumnDef(column.entity, column, reqPage);
  } else if (column.metaColumn) {
    return getMetaColumnDef(column.metaColumn as ERequirementsTableColumn, column, reqPage);
  }
};

export const useReqTableColumnDefs = (reqPage: IRequirementsPage): ColDef[] => {
  const columnsOrder = appStore.env.columnsOrder.requirementsTable;
  const workspace = useWorkspace();
  const { orderedColumns } = reqPage;
  const columnDefs = useMemo(
    () => {
      return [
        getActionsColumnDef(reqPage),
        ...orderedColumns.map(column => mapTableColumnToColumnDef(column, reqPage)).filter(isDefined),
        getAddNewColumnDef(reqPage, workspace),
      ] satisfies ColDef<IRequirementBlock>[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reqPage, orderedColumns, orderedColumns.length, columnsOrder, workspace]
  );

  return columnDefs;
};
