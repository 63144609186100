import { ICreateWorkspaceSettingsDto, IUpdateWorkspaceSettingsDto } from "@rollup-api/models/workspace-settings/workspaceSettings.dto";
import { EntityType } from "@store/types";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "./client";

export class WorkspaceSettings extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return "workspace-settings";
  }

  public create = <T = any>(entityType: EntityType, dto: ICreateWorkspaceSettingsDto) => {
    trackSegmentEvent("workspace-settings:create", { entityType, ...dto });
    const { workspaceId } = this.parent;
    const workspaceIdObj = workspaceId ? { workspaceId } : {};
    return this.instance.post<T>(`${this.endpoint}`, { settings: { entityType, ...dto, ...workspaceIdObj } });
  };

  public update = <T = any>(entityType: EntityType, dto: IUpdateWorkspaceSettingsDto) => {
    trackSegmentEvent("workspace-settings:update", { entityType, ...dto });
    return this.instance.put<T>(this.endpoint, { settings: { entityType, ...dto } });
  };

  public getByEntityType = <T = any>(entityType: EntityType) => {
    trackSegmentEvent("workspace-settings:get", { entityType });
    const { workspaceId } = this.parent;
    return this.instance.get<T>(`${this.endpoint}?entityType=${entityType}${workspaceId ? `&workspaceId=${workspaceId}` : ""}`);
  };
}
