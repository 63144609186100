import classNames from "classnames";
import { observer } from "mobx-react";

import { Popover } from "@components/Popover";
import UserSelectorMenu from "@components/SelectorMenus/UserSelector";
import UserInfo from "@components/UserInfo/UserInfo";
import { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import styles from "./UserSelectCell.module.scss";

type UserSelectCellProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  disabled?: boolean;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined>;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

const UserSelectCell = (props: UserSelectCellProps) => {
  const { statusDefinition, statusInstance, disabled, onAddStatusInstance, onDeleteStatusInstance } = props;

  const handleChange = async (selectedUserIds?: string[]) => {
    if (!statusInstance) {
      await onAddStatusInstance(statusDefinition, JSON.stringify(selectedUserIds));
    } else {
      if (!selectedUserIds) {
        onDeleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(JSON.stringify(selectedUserIds));
      }
    }
  };

  return (
    <Popover
      targetTagName="div"
      targetProps={{ className: styles.userSelectCellPopover }}
      content={<UserSelectorMenu maxHeight={400} onChange={handleChange} selectedUserIds={statusInstance?.arrayValue} />}
      position="bottom"
      disabled={disabled}
    >
      <div data-testid="userSelectCell" className={classNames(styles.userSelectCell, { [styles.disabled]: disabled })}>
        {statusInstance?.arrayValue.length ? (
          <>{statusInstance?.arrayValue.map(id => <UserInfo key={id} size="extra-small" userId={id} />)}</>
        ) : null}
      </div>
    </Popover>
  );
};

export default observer(UserSelectCell);
