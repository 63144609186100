import * as React from "react";
const SvgSpreadsheet = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L15.9863 4.71432L13.5996 1.31445Z", fill: "#0F9D58" })), /* @__PURE__ */ React.createElement("mask", { id: "mask1_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M7.87109 11.7573V18.7999H15.5086V11.7573H7.87109ZM11.2125 17.8285H8.8258V16.6143H11.2125V17.8285ZM11.2125 15.8857H8.8258V14.6715H11.2125V15.8857ZM11.2125 13.943H8.8258V12.7287H11.2125V13.943ZM14.5539 17.8285H12.1672V16.6143H14.5539V17.8285ZM14.5539 15.8857H12.1672V14.6715H14.5539V15.8857ZM14.5539 13.943H12.1672V12.7287H14.5539V13.943Z", fill: "#F1F1F1" })), /* @__PURE__ */ React.createElement("mask", { id: "mask2_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.0186 6.7168L19.3278 12.1178V7.143L14.0186 6.7168Z", fill: "url(#paint0_linear_5775_11270)" })), /* @__PURE__ */ React.createElement("mask", { id: "mask3_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445V5.68573C13.5996 6.49075 14.2404 7.14281 15.0316 7.14281H19.3277L13.5996 1.31445Z", fill: "#87CEAC" })), /* @__PURE__ */ React.createElement("mask", { id: "mask4_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M5.48476 1.31445C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V2.89297C4.05273 2.09157 4.69715 1.43588 5.48476 1.43588H13.5996V1.31445H5.48476Z", fill: "white", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask5_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M18.1115 22.564H5.70059C4.91297 22.564 4.26855 21.9083 4.26855 21.1069V21.2284C4.26855 22.0298 4.91297 22.6855 5.70059 22.6855H18.1115C18.8991 22.6855 19.5435 22.0298 19.5435 21.2284V21.1069C19.5435 21.9083 18.8991 22.564 18.1115 22.564Z", fill: "#263238", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask6_5775_11270", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 16, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask6_5775_11270)" }, /* @__PURE__ */ React.createElement("path", { d: "M15.0316 7.14312C14.2404 7.14312 13.5996 6.49106 13.5996 5.68604V5.80745C13.5996 6.6125 14.2404 7.26453 15.0316 7.26453H19.3277V7.14312H15.0316Z", fill: "#263238", fillOpacity: 0.1 }), /* @__PURE__ */ React.createElement("path", { d: "M13.5996 1.31445H5.48476C4.69715 1.31445 4.05273 1.97014 4.05273 2.77154V21.228C4.05273 22.0294 4.69715 22.6851 5.48476 22.6851H17.8957C18.6833 22.6851 19.3277 22.0294 19.3277 21.228V7.14281L13.5996 1.31445Z", fill: "url(#paint1_radial_5775_11270)" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_5775_11270", x1: 16.6735, y1: 7.18053, x2: 16.6735, y2: 12.1185, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#263238", stopOpacity: 0.2 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#263238", stopOpacity: 0.02 })), /* @__PURE__ */ React.createElement("radialGradient", { id: "paint1_radial_5775_11270", cx: 0, cy: 0, r: 1, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(4.53665 1.73924) scale(24.6307 25.0617)" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "white", stopOpacity: 0.1 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "white", stopOpacity: 0 }))));
export default SvgSpreadsheet;
