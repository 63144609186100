import { KeyboardEvent, useCallback } from "react";
import { EditorView } from "@tiptap/pm/view";
import { Editor } from "@tiptap/react";
import { isAlive } from "mobx-state-tree";
import { v4 as uuidV4 } from "uuid";

import { createMentionTagComponent } from "@components/Reports/Editor/Extentions/Mention/MentionSuggestion";
import {
  getMentionAttributes,
  getMentionItemFromText,
  handleBackspaceIfMention,
} from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { ARROW_DOWN_KEY, ARROW_UP_KEY, BACKSPACE_KEY, ENTER_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { getReportBlockTypeByTextInput } from "@utilities/ReportBlocks";
import { focusNextBlock, focusPreviousBlock } from "@utilities/TipTap";

import { allContentExtensions, TIPTAP_EMPTY_CONTENT } from "./constants";
import { focusReportBlockById } from "./utils";

export const endPositionFixer = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.quote:
      return 2;
    default:
      return 1;
  }
};

export const startPositionFixer = (type: RollupEditorType) => {
  switch (type) {
    case RollupEditorType.quote:
      return 2;
    case RollupEditorType.table:
      return 0;
    default:
      return 1;
  }
};

// TODO refactor args to take a single params object
export const useEditorHandlers = (reportBlock: IReportBlock, editor?: Editor | null, workspaceId?: string) => {
  const reportBlocks = appStore.workspaceModel?.reportBlocks;
  const deleteEmptyBlock = () => {
    if (editor?.isEmpty && reportBlocks) {
      focusPreviousBlock(reportBlock, reportBlocks);
      appStore.workspaceModel?.deleteReportBlock(reportBlock);
    }
  };

  const bulkCreateReportBlocks = useCallback(
    (dto: { type: RollupEditorType; label: string }[]) => {
      const report = appStore.workspaceModel?.reportsMap.get(reportBlock.parentReport);

      if (report && dto.length) {
        const orderIndex = report.reportBlocks.findIndex((i: string) => i === reportBlock.id) + 1;
        const lastCreatedBlockId = appStore.workspaceModel?.addReportBlocks(report, dto, orderIndex);
        lastCreatedBlockId && focusReportBlockById(lastCreatedBlockId, true);
      }

      return;
    },
    [reportBlock]
  );

  const createNewBlock = useCallback(
    (type = RollupEditorType.p, label = "", above = false, propsBlock?: IReportBlock) => {
      const block = propsBlock || reportBlock;
      const report = appStore.workspaceModel?.reportsMap.get(block.parentReport);

      if (report) {
        const indexModifier = above ? 0 : 1;
        const orderIndex = report.reportBlocks.findIndex((i: string) => i === block.id) + indexModifier;
        const id = uuidV4();
        const newBlockId = appStore.workspaceModel?.addReportBlock(report, type, label, orderIndex, id);
        const focusStart = above || label;
        focusReportBlockById(above ? block.id : id, !focusStart);
        return newBlockId;
      }

      return;
    },
    [reportBlock]
  );

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType) => {
    if (event.key === ENTER_KEY && !event.shiftKey) {
      const cursorPosition = editor?.state.selection.$anchor.pos || 1;
      const isAbove = editor?.state.selection.$anchor.pos === 1 && !editor?.isEmpty;
      const doc = editor?.state?.doc;
      let label = "";

      if (doc && !isAbove) {
        const textBefore = doc.cut(0, cursorPosition).toJSON();
        const content = doc.cut(cursorPosition, doc.content.size).toJSON();
        const temporaryEditorInstance = new Editor({ content, extensions: allContentExtensions });
        const temporaryContent = temporaryEditorInstance.getHTML();
        const temporaryText = temporaryEditorInstance.getText();
        if (temporaryText && TIPTAP_EMPTY_CONTENT !== temporaryContent) {
          label = temporaryContent;
        }
        editor?.commands.setContent(textBefore);
      }

      createNewBlock(type, label, isAbove);
    }
  };

  const handleModEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType, label?: string) => {
    if ((event.key === ENTER_KEY && event.metaKey) || (event.key === ENTER_KEY && event.ctrlKey)) {
      createNewBlock(type, label);
    }
  };

  const handleShiftEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType, label?: string) => {
    if (event.key === ENTER_KEY && event.shiftKey) {
      createNewBlock(type, label);
    }
  };

  const handleBackspaceKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== BACKSPACE_KEY || !editor) {
      return;
    }

    const isMention = handleBackspaceIfMention({ editor });
    if (isMention) {
      return;
    }

    if (reportBlock.type !== RollupEditorType.p && editor?.isEmpty) {
      reportBlock.updateType(RollupEditorType.p);
      return;
    }

    deleteEmptyBlock();
  };

  const handleArrowDownKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ARROW_DOWN_KEY && reportBlocks && reportBlocks.length > 1) {
      const endPosition = endPositionFixer(reportBlock.type);
      const canJumpUp = (editor?.getText().length || 0) + endPosition <= (editor?.state.selection.$anchor.pos || 0);
      if (canJumpUp) {
        focusNextBlock(reportBlock, reportBlocks);
      }
    }
  };

  const handleArrowUpKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ARROW_UP_KEY && reportBlocks && reportBlocks.length > 1) {
      const startPosition = startPositionFixer(reportBlock.type);
      const canJumpUp = editor?.state.selection.$anchor.pos === startPosition || editor?.isEmpty;
      if (canJumpUp) {
        focusPreviousBlock(reportBlock, reportBlocks);
      }
    }
  };

  const handlePaste = useCallback(
    (_view: EditorView, e: ClipboardEvent) => {
      if (!editor || !workspaceId) return;
      const pastedText = e.clipboardData?.getData("text") || "";
      const futureBlocks = pastedText.split("\n");

      if (futureBlocks.length > 1) {
        const bulkCreateDto: { type: RollupEditorType; label: string }[] = [];
        futureBlocks.map((text, index) => {
          // we skip the first item because existing block will be populated from it
          if (index) {
            const { type, trimmedText } = getReportBlockTypeByTextInput(text);
            bulkCreateDto.push({ type, label: trimmedText });
          }
        });
        bulkCreateReportBlocks(bulkCreateDto);
      }

      const entity = futureBlocks[0] ? getMentionItemFromText(futureBlocks[0], workspaceId) : undefined;
      const mentionTagAttrs = entity && getMentionAttributes(entity);

      if (mentionTagAttrs) {
        const mentionTagComponent = createMentionTagComponent(mentionTagAttrs.id, mentionTagAttrs.label, true);
        // append content to the editor
        editor?.chain().focus().insertContent(mentionTagComponent).run();
      } else {
        const { type, trimmedText } = getReportBlockTypeByTextInput(futureBlocks[0]);
        editor?.chain().focus().insertContent(trimmedText).run();
        type !== reportBlock.type && reportBlock.updateType(type);
      }

      editor?.chain().focus();
      return true; // prevents default pasting
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, reportBlock, workspaceId]
  );

  const handleBlockUpdate = useCallback(
    (content: string) => {
      if (isAlive(reportBlock)) {
        reportBlock.updateText(content);
      }
    },
    [reportBlock]
  );

  return {
    createNewBlock,
    handleEnterKeyDown,
    handleShiftEnterKeyDown,
    handleModEnterKeyDown,
    handleBackspaceKeyDown,
    handleArrowUpKey,
    handleArrowDownKey,
    handlePaste,
    handleBlockUpdate,
    bulkCreateReportBlocks,
  };
};
