import { observer } from "mobx-react";

import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

import { LinkedBlockButton } from "../../LinkedBlockButton";

import "./LinkedBlockCell.scss";

type Props = {
  requirementBlock: IRequirementBlock;
};

const LinkedBlockCell = (props: Props) => {
  const { requirementBlock } = props;

  return (
    <div className="linked-block-cell">
      <LinkedBlockButton className="linked-block-cell--button" noneClassName="linked-block-cell--none" reqBlock={requirementBlock} />
    </div>
  );
};

export default observer(LinkedBlockCell);
