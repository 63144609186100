import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { cast, destroy, flow, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { RequirementBlock } from "@rollup-api/models/requirementBlock";
import { IGetRequirementsSettingsRto } from "@rollup-api/models/workspace-settings/workspaceSettings.dto";
import { NoReturnGenerator } from "@rollup-types/typeUtils";
import { IRequirementBlock, IRequirementBlockSnapshotIn, RequirementBlockStore } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage, IRequirementsPageMobxType, RequirementsPageStore } from "@store/Requirements/RequirementsPageStore";
import { RequirementsSettingsStore } from "@store/Requirements/RequirementsSettingsStore";
import { EntityType, ICreateRequirementsPageData } from "@store/types";
import { mapRtoToSnapshot } from "src/services/services.utils";

import { rollupClient } from "../../core/api";

export const RequirementsPageModuleStore = types
  .model("RequirementsPageModuleStore", {
    requirementsPageMap: types.map<IRequirementsPageMobxType>(RequirementsPageStore),
    requirementBlockMap: types.map(types.late(() => RequirementBlockStore)),
    settings: types.optional(RequirementsSettingsStore, {}),
  })
  .views(self => ({
    get values(): IRequirementsPage[] {
      return toArray<IRequirementsPage>(self.requirementsPageMap);
    },
    get(id: string): IRequirementsPage | undefined {
      return self.requirementsPageMap.get(id);
    },
    getBlock(id: string): IRequirementBlock | undefined {
      return self.requirementBlockMap.get(id);
    },
  }))
  .actions(self => ({
    loadSettings: flow(function* (): NoReturnGenerator<AxiosResponse<IGetRequirementsSettingsRto | undefined>> {
      const response = yield rollupClient.workspaceSettings.getByEntityType(EntityType.RequirementsDocument);
      if (response.data) {
        self.settings = cast(response.data.settings);
      } else {
        rollupClient.workspaceSettings.create(EntityType.RequirementsDocument, self.settings);
      }
    }),
    delete(id: string, notify = true) {
      const page = self.get(id);

      if (notify) {
        page?.columns.forEach(column => rollupClient.tableColumns.delete(page.id, column.id));
        rollupClient.requirementsPages.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting requirements pages", err);
        });
      }

      destroy(page);
      return true;
    },
    deleteBlock(id: string): boolean {
      return self.requirementBlockMap.delete(id);
    },
    updateBlock(snapshot: IRequirementBlockSnapshotIn): IRequirementBlock {
      return self.requirementBlockMap.put(snapshot);
    },
    addExistingRequirementBlocks(blocks: RequirementBlock[]): void {
      blocks.forEach(block => {
        const reqPage = self.get(block.parentPage);
        if (!reqPage) {
          console.error(`Requirements page with id ${block.parentPage} not found`);
          return;
        }
        self.requirementBlockMap.put({ ...mapRtoToSnapshot(block) });
        reqPage.addBlock(block.id);
      });
    },
  }))
  .actions(self => ({
    createRequirementsPage: flow(function* addRequirementsPage(
      data: ICreateRequirementsPageData = {},
      notify = true
    ): Generator<any, IRequirementsPage | undefined, any> {
      const { id = uuidv4(), label = "New Document" } = data;
      const newPage = self.requirementsPageMap.put({
        id,
        label,
        replicated: !notify,
      });

      if (notify) {
        try {
          const res = yield rollupClient.requirementsPages.create({ id, label });
          if (res.status === 201) {
            newPage.setReplicated();
          } else {
            showApiErrorToast("Error creating requirements document", new Error());
            self.delete(newPage.id, false);
            return undefined;
          }
        } catch (err) {
          console.warn(err);
          showApiErrorToast("Error creating requirements document", err as Error);
          return undefined;
        }
      }

      return newPage;
    }),
  }));
