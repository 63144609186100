import { CreateTableColumnDto } from "@rollup-api/models/table-column/creteTableColumnDto";
import { TableColumn } from "@rollup-api/models/table-column/tableColumn.model";
import { UpdateTableColumnDto } from "@rollup-api/models/table-column/updateTableColumnDto";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "./client";

export class TableColumns extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private getEndpoint(tableId: string) {
    return `workspace/${this.parent.workspaceId}/tables/${tableId}/table-columns`;
  }

  public getAll = (tableId: string) => {
    return this.instance.get<TableColumn[]>(this.getEndpoint(tableId));
  };

  public create = (tableId: string, dto: CreateTableColumnDto) => {
    trackSegmentEvent("table-column:create", { tableId, dto });
    return this.instance.post<TableColumn>(this.getEndpoint(tableId), dto);
  };

  public update = (tableId: string, id: string, dto: UpdateTableColumnDto) => {
    trackSegmentEvent("table-column:update", { tableId, id, dto });
    return this.instance.patch<TableColumn>(`${this.getEndpoint(tableId)}/${id}`, dto);
  };

  public delete = (tableId: string, id: string) => {
    trackSegmentEvent("table-column:delete", { tableId, id });
    return this.instance.delete<TableColumn>(`${this.getEndpoint(tableId)}/${id}`);
  };
}
