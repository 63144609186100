import { CSSProperties } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import * as icons from "@assets/icons/custom";
import { hasActiveIcon, isActiveOnlyIcon } from "@components/CustomIcon/CusomIcon.utils";

import { ICustomIconName, ModuleColorfulIcon } from "./CustomIcon.types";

import styles from "./CustomIcon.module.scss";

interface ICustomIconProps {
  icon: ICustomIconName;
  className?: string;
  size?: number;
  style?: CSSProperties;
  active?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

const CustomIcon = (props: ICustomIconProps) => {
  const { icon, className, style, size, active, disabled, onClick } = props;

  const renderIcon = () => {
    const IconActive = hasActiveIcon(icon) ? icons[`${icon}Active`] : undefined;
    const commonStyles = { width: size, height: size, ...style };
    const isActiveOnly = isActiveOnlyIcon(icon);

    if ((active || isActiveOnly) && IconActive) {
      return <IconActive style={commonStyles} />;
    } else if (isActiveOnly) {
      console.error("IconActive is not defined for icon: ", icon);
      return null;
    }

    const IconDark = icons[`${icon}Dark`];
    const IconLight = icons[`${icon}Light`];

    const isColoredIcon = Object.values(ModuleColorfulIcon).includes(icon as ModuleColorfulIcon);
    const classNameMap = { "custom-icon--active": active, "custom-icon--colored": isColoredIcon, "custom-icon--disabled": disabled };

    return (
      <>
        <IconDark className={classNames("custom-icon--dark", classNameMap)} style={commonStyles} />
        <IconLight className={classNames("custom-icon--light", classNameMap)} style={commonStyles} />
      </>
    );
  };

  return (
    <div className={classNames(styles.customIcon, className)} onClick={onClick}>
      {renderIcon()}
    </div>
  );
};

export default observer(CustomIcon);
