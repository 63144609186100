import { ReactNode } from "react";
import { DialogProps as BpDialogProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import DialogBase from "./DialogBase";

import "./Dialog.scss";

export enum DialogType {
  Simple = "simple",
  WithLeftPane = "withLeftPanel",
  WithRightPane = "withRightPanel",
}

export enum DialogSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

const validModalTypeSizeCombinations: Record<DialogType, DialogSize[]> = {
  [DialogType.Simple]: [],
  [DialogType.WithLeftPane]: [DialogSize.Small, DialogSize.Medium],
  [DialogType.WithRightPane]: [DialogSize.Medium, DialogSize.Large],
};

export interface DialogProps extends BpDialogProps {
  type?: DialogType;
  size?: DialogSize;
  titleButtons?: ReactNode[];
  footerButtons?: ReactNode[];
  leftPane?: ReactNode;
  rightPane?: ReactNode;
  bodyClassName?: string;
}

const getDefaultSize = (type: DialogType) => {
  if (type === DialogType.WithLeftPane) {
    return DialogSize.Small;
  } else if (type === DialogType.WithRightPane) {
    return DialogSize.Large;
  }
};

export const Dialog = ({
  type = DialogType.Simple,
  size = getDefaultSize(type),
  children,
  titleButtons,
  footerButtons,
  leftPane,
  rightPane,
  bodyClassName,
  ...rest
}: DialogProps) => {
  // Check if the type and size are compatible
  if (size && !validModalTypeSizeCombinations[type].includes(size)) {
    throw new Error(`Invalid modal type and size combination: ${type} and ${size}`);
  }

  // size class for all dialog types
  const sizeClass = classNames({
    "new-dialog--size-small": size === DialogSize.Small,
    "new-dialog--size-medium": size === DialogSize.Medium,
    "new-dialog--size-large": size === DialogSize.Large,
    "new-dialog--size-simple": !size,
  });

  // Simple dialog
  if (type === DialogType.Simple) {
    const bodyWrapperClass = classNames({
      "new-dialog--simple--body--with-body-and-footer": children && footerButtons,
      "new-dialog--simple--body--with-body-without-footer": children && !footerButtons,
      "new-dialog--simple--body--without-body-with-footer": !children && footerButtons,
    });
    const buttonFooterWrapperClass = classNames({
      "new-dialog--simple--footer--with-body-and-footer": children && footerButtons,
      "new-dialog--simple--footer--with-body-without-footer": children && !footerButtons,
      "new-dialog--simple--footer--without-body-without-footer": !children && footerButtons,
    });
    return (
      <DialogBase className={sizeClass} titleButtons={titleButtons} {...rest}>
        {children && <div className={classNames(bodyWrapperClass, bodyClassName)}>{children}</div>}
        {footerButtons && <div className={classNames("new-dialog--simple--footer", buttonFooterWrapperClass)}> {footerButtons}</div>}
      </DialogBase>
    );
  }

  // Dialog with left pane
  if (type === DialogType.WithLeftPane) {
    return (
      <DialogBase className={sizeClass} titleButtons={titleButtons} {...rest}>
        <div
          className={classNames({
            "new-dialog--with-left-pane--medium": size === DialogSize.Medium,
            "new-dialog--with-left-pane--small": size !== DialogSize.Medium,
          })}
        >
          <div className="new-dialog--with-left-pane--left-pane">{leftPane}</div>
          <div className="new-dialog--with-left-pane--right-pane">
            <div className={classNames("new-dialog--with-left-pane--right-pane--body", bodyClassName)}>{children}</div>
            {footerButtons && <div className="new-dialog--with-left-pane--right-pane--footer">{footerButtons}</div>}
          </div>
        </div>
      </DialogBase>
    );
  }

  // Dialog with right pane
  if (type === DialogType.WithRightPane) {
    return (
      <DialogBase className={sizeClass} titleButtons={titleButtons} {...rest}>
        <div className="new-dialog--with-right-pane">
          <div
            className={classNames({
              "new-dialog--with-right-pane--body--medium": size === DialogSize.Medium,
              "new-dialog--with-right-pane--body--large": size !== DialogSize.Medium,
            })}
          >
            <div className={classNames("new-dialog--with-right-pane--body--main-pane", bodyClassName)}>{children}</div>
            <div className="new-dialog--with-right-pane--body--right-pane">{rightPane}</div>
          </div>
          {footerButtons && <div className="new-dialog--with-right-pane--footer">{footerButtons}</div>}
        </div>
      </DialogBase>
    );
  }
};

export default observer(Dialog);
