import { createBrowserRouter, createRoutesFromElements, Navigate, redirect, Route, RouterProvider } from "react-router-dom";
import { observer } from "mobx-react";

import { EPageName } from "@router/hooks/useAppNavigate";
import appStore, { AuthStatus } from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import { appIndexLoader } from "./elements/AppIndex";
import TopBarPlaceholder from "./elements/AppLayout/TopBarPlaceholder";
import { blockIndexLoader } from "./elements/BlockIndex";
import * as Element from "./elements";

import styles from "./AppRouter.module.scss";

/** Main function. */
function AppRouter() {
  const isPending = appStore.authStatus === AuthStatus.Pending;
  const isFetchingProfile = appStore.authStatus === AuthStatus.FetchingProfile;
  const isLoggedIn = appStore.authStatus === AuthStatus.LoggedIn;
  const isLoggedOut = appStore.authStatus === AuthStatus.LoggedOut;

  const renderRedirectRoute = (sourceRoute: string, targetRoute: string, disableMatchSubRoutes?: boolean) => {
    return (
      <Route
        path={`${sourceRoute}${disableMatchSubRoutes ? "" : "/*"}`}
        loader={({ request }) => {
          const newUrl = request.url.replace(sourceRoute, targetRoute);
          return redirect(newUrl);
        }}
      />
    );
  };

  const isLoading = isPending || isFetchingProfile;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {isLoggedOut && <Route path="*" element={<Element.Login />} />}
        {isLoading && <Route path="*" element={<Element.RouterLoading />} />}
        {isLoggedIn && (
          <Route element={<Element.AppLayout />}>
            <Route index element={<Element.AppIndex />} loader={appIndexLoader} />
            <Route path="*" element={<Element.CatchAll />} />
            <Route path="login" element={<Navigate to="/" />} />
            <Route path="hoops/:attachmentId" element={<Element.HoopsViewIndex />} />
            <Route path="settings" element={<Element.SettingsLayout />}>
              <Route index element={<Element.SettingsAccount />} />
              <Route path="notifications" element={<Element.SettingsNotifications />} />
              <Route path="general" element={<Element.SettingsGeneral />} />
              <Route path="members" element={<Element.SettingsMembers />} />
              <Route path="imports" element={<Element.SettingsImports />} />
              <Route path="plan-and-billing" element={<Element.SettingsPlanAndBilling />} />
              <Route path="execution-environments" element={<Element.SettingsExecutionEnvironments />} />
              <Route path="marketplace" element={<Element.SettingsMarketplace />}>
                <Route path=":tab" element={<Element.SettingsMarketplace />} />
              </Route>
              <Route path="integrations/:integrationId">
                <Route index element={<Navigate to="overview" replace />} />
                <Route path="overview" element={<Element.SettingsIntegrationsOverview />} />
                <Route path="config" element={<Element.SettingsIntegrationsConfig />} />
              </Route>
              <Route path="active-connections" element={<Element.SettingsActiveConnections />} />
              <Route path="api-keys" element={<Element.SettingsApiKeys />} />
              <Route path="webhooks" element={<Element.SettingsWebhooks />} />
              <Route path="workspaces" element={<Element.SettingsWorkspaces />} />
              <Route path="oauth-applications" element={<Element.SettingsOauthApplications />} />
            </Route>
            <Route path="inbox" element={<Element.InboxLayout />}>
              <Route index element={<Element.InboxIndex />} />
            </Route>
            <Route path={EPageName.ProjectManagement} element={<Element.PmLayout />}>
              <Route index element={<Navigate to={EPageName.ProjectManagementMyTasks} replace />} />
              <Route path={EPageName.ProjectManagementMyTasks} element={<Element.DefaultLayout />}>
                <Route index element={<Element.PmMyTasksIndex />} />
              </Route>
              <Route path={EPageName.ProjectManagementAllTasks} element={<Element.DefaultLayout />}>
                <Route index element={<Element.PmTasksIndex />} />
              </Route>
              <Route path={EPageName.ProjectManagementProjects} element={<Element.DefaultLayout />}>
                <Route index element={<Element.PmProjectsIndex />} />
                <Route path=":projectId" element={<Element.DefaultLayout />}>
                  <Route index element={<Element.PmProjectIndex />} />
                </Route>
              </Route>
            </Route>
            <Route path="workspaces/:workspaceId" element={<Element.WorkspaceLayout />}>
              <Route index element={<Navigate to="modeling" replace />} />
              <Route path={EPageName.Requirements} element={<Element.RequirementsLayout />}>
                <Route index element={<Element.RequirementsIndex />} />
                <Route path={EPageName.Configuration} element={<Element.DefaultLayout />}>
                  <Route index element={<Element.RequirementsConfiguration />} />
                </Route>
                <Route path=":requirementId" element={<Element.DefaultLayout />}>
                  <Route index element={<Element.RequirementIndex />} />
                </Route>
              </Route>
              {renderRedirectRoute(EPageName.Reports, EPageName.Knowledgebase)}
              <Route path={EPageName.Knowledgebase} element={<Element.ReportsLayout />}>
                <Route index element={<Element.ReportsIndex />} />
                <Route path=":reportId" element={<Element.DefaultLayout />}>
                  <Route index element={<Element.ReportIndex />} />
                </Route>
              </Route>
              <Route path="imports" element={<Element.DefaultLayout />}>
                <Route index element={<Element.ImportsIndex />} />
              </Route>

              <Route path="analysis" element={<Element.AnalysisLayout />}>
                <Route index element={<Element.AnalysisIndex />} />
                <Route path=":codeBlockId" element={<Element.DefaultLayout />}>
                  <Route index element={<Element.CodeBlockIndex />} />
                </Route>
                <Route path="codeblocks/:codeBlockId" element={<Element.DefaultLayout />}>
                  <Route index element={<Element.CodeBlockIndex />} />
                </Route>
                {/* <Route path="spreadsheets/:spreadsheetId" element={<Element.DefaultLayout />}>
                  <Route index element={<Element.SpreadsheetIndex />} />
                </Route> */}
              </Route>

              <Route path={EPageName.Pdm} element={<Element.HardwareCatalogLayout />}>
                <Route index element={<Navigate to={EPageName.ItemCatalog} />} />
                <Route path={EPageName.ItemCatalog} element={<Element.CatalogItemsIndex />} />
                <Route path={EPageName.Settings} element={<Element.SettingsPdmRevisionCode />} />
                {appStore.env.featureFlags.enabled(FeatureFlag.BOM) && (
                  <>
                    <Route path={EPageName.Bom} element={<Element.BomTablesIndex />} />
                    <Route path="bom/:tableId" element={<Element.DefaultLayout />}>
                      <Route index element={<Element.BomTableIndex />} />
                    </Route>
                  </>
                )}
              </Route>
              <Route path={EPageName.DataConnections} element={<Element.DefaultLayout />}>
                <Route index element={<Element.DataConnectionsIndex />} />
              </Route>
              <Route path={EPageName.Modeling} element={<Element.ModelingLayout />}>
                <Route index element={<Navigate to="blocks" replace />} />
                <Route path={EPageName.Blocks} element={<Element.DefaultLayout />}>
                  <Route index element={<Element.BlockIndex />} loader={blockIndexLoader} />
                  <Route path=":blockId" element={<Element.DefaultLayout />}>
                    <Route index element={<Element.ActiveBlockIndex />} />
                  </Route>
                </Route>
                <Route path={EPageName.Properties} element={<Element.DefaultLayout />}>
                  <Route index element={<Element.PropertiesIndex />} />
                </Route>
                <Route path={EPageName.Statuses} element={<Element.DefaultLayout />}>
                  <Route index element={<Element.StatusesIndex />} />
                </Route>
                {appStore.env.featureFlags.enabled(FeatureFlag.CUSTOM_UNITS) && (
                  <Route path={EPageName.CustomUnits} element={<Element.DefaultLayout />}>
                    <Route index element={<Element.CustomUnitsIndex />} />
                  </Route>
                )}
                <Route path={EPageName.Threads} element={<Element.DefaultLayout />}>
                  <Route path={EPageName.PropertyRelations} element={<Element.DefaultLayout />}>
                    <Route index element={<Element.ThreadsDag />} />
                    <Route path=":propertyId" element={<Element.DefaultLayout />}>
                      <Route index element={<Element.ThreadsDag />} />
                    </Route>
                  </Route>
                  <Route path={EPageName.Hierarchy} element={<Element.DefaultLayout />}>
                    <Route index element={<Element.ThreadsHierarchy />} />
                  </Route>
                </Route>
                <Route path={EPageName.Table} element={<Element.DefaultLayout />}>
                  <Route index element={<Element.TableIndex />} />
                </Route>
              </Route>
            </Route>
          </Route>
        )}
      </>
    )
  );

  return (
    <div className={styles.appRouter}>
      <TopBarPlaceholder />
      <RouterProvider router={router} />
    </div>
  );
}

/** Exports. */
export default observer(AppRouter);
