import { useCallback } from "react";
import { Icon, Position } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { UnknownTag } from "@components/Reports/Editor/Extentions/Mention/MentionTag/UnknownTag";
import { TitledTooltip } from "@components/Shared";
import { useAppNavigate } from "@router/hooks";
import { IPropertyInstance } from "@store/PropertyInstanceStore";

import styles from "./PropertyInstanceTag.module.scss";

interface IPropertyInstanceTagProps {
  propertyInstance: IPropertyInstance;
  showValue?: boolean;
  className?: string;
  labelClassName?: string;
}

const PropertyInstanceTag = (props: IPropertyInstanceTagProps) => {
  const { propertyInstance, showValue, className, labelClassName } = props;
  const { navigateToProperty } = useAppNavigate();

  const handleInstanceClicked = useCallback(() => {
    if (propertyInstance.parentBlock && propertyInstance.id) {
      navigateToProperty(propertyInstance.id);
    }
  }, [navigateToProperty, propertyInstance.id, propertyInstance.parentBlock]);

  if (!propertyInstance.propertyDefinition || !propertyInstance.parentBlock) {
    return <UnknownTag />;
  }

  const tagLabel = showValue
    ? `${propertyInstance.numericText}`
    : `${propertyInstance.parentBlock.label}:${propertyInstance.propertyDefinition.label}`;

  const tooltipMessage = showValue ? "" : `Value: ${propertyInstance.numericText}`;

  return (
    <TitledTooltip placement={Position.BOTTOM} title={propertyInstance.path} content={tooltipMessage}>
      <div className={classNames(styles.propertyInstanceTag, className)} onClick={handleInstanceClicked}>
        {!showValue && <Icon icon="property" />}
        <span className={labelClassName}>{tagLabel}</span>
      </div>
    </TitledTooltip>
  );
};

export default observer(PropertyInstanceTag);
