import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import { getLevelCellText } from "@components/Requirements/Page/RequirementPageUtils";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

type Props = {
  requirementBlock: IRequirementBlock;
};

const LevelCell = (props: Props) => {
  const { requirementBlock } = props;

  const renderMenuItem = (level: number | null) => (
    <MenuItem
      active={requirementBlock.level === level}
      onClick={() => requirementBlock.setLevel(level)}
      text={getLevelCellText(level)}
      e2eIdentifiers={getLevelCellText(level)}
    />
  );

  const getMenuContent = () => (
    <Menu>
      {renderMenuItem(1)}
      {renderMenuItem(2)}
      {renderMenuItem(3)}
      {renderMenuItem(0)}
      {renderMenuItem(null)}
    </Menu>
  );

  return (
    <CellDropdown
      disabled={requirementBlock.locked}
      text={getLevelCellText(requirementBlock.level)}
      content={getMenuContent()}
      e2eIdentifiers={getLevelCellText(requirementBlock.level)}
    />
  );
};

export default observer(LevelCell);
