import { Divider } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import Text, { TextColor, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { AnalysisType } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import AnalysisSidebarItem from "../AnalysisSidebarItem/AnalysisSidebarItem";

import "./AnalysisSidebar.scss";

export interface AnalysisSidebarProps {
  minimal?: boolean;
}
const AnalysisSidebar = ({ minimal }: AnalysisSidebarProps) => {
  const workspace = useWorkspace();
  const codeBlocks = workspace.analysis.analyses.filter(analysis => analysis.analysisType === AnalysisType.CodeBlock);
  const spreadsheets = workspace.analysis.analyses.filter(analysis => analysis.analysisType === AnalysisType.Spreadsheet);

  return (
    <div className="analysis-sidebar">
      <Text variant={TextVariant.Label} color={TextColor.Secondary}>
        Code Blocks
      </Text>
      {codeBlocks.toReversed().map(block => (
        <AnalysisSidebarItem key={block.id} analysis={block} minimal={minimal} />
      ))}
      {appStore.env.featureFlags.enabled(FeatureFlag.SPREADSHEET_SUPPORT) && (
        <>
          <Divider />
          <Text variant={TextVariant.Label} color={TextColor.Secondary}>
            Spreadsheets
          </Text>
          {spreadsheets.toReversed().map(spreadsheet => (
            <AnalysisSidebarItem key={spreadsheet.id} analysis={spreadsheet} minimal={minimal} />
          ))}
        </>
      )}
    </div>
  );
};

export default observer(AnalysisSidebar);
